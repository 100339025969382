import useForms from "../../../context/forms/useForms";
import { tableColumnParser } from "../../../util/tableColumnParser";
import useApi from "hooks/useApi";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import TableAction from "Components/TableAction";
import ViewForm from "Components/ViewForm";
import { SwitchButton } from "Components";
import { toast } from "react-toastify";
import useUser from "hooks/useUser";
import MyDocument from "./pdfDocument";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import moment from "moment";
import { MdRemoveRedEye } from "react-icons/md";
import { calculateDiscount } from "util/calculate";

const API_NAME = "workorder";

export default function useTable({ page, action }) {
  const { GET, PUT } = useApi();
  const queryClients = useQueryClient();
  const { getForm, loading } = useForms();
  const form = getForm(API_NAME);
  const { accessPath } = useUser(form?.formName);

  const filterOutData = [
    "workOrderNo",
    "workOrderId",
    "signatureLine",
    "additionalGSTDetails",
    "additionalContactNo",
    "additionalLocationNameOfContactPerson",
    "additionalEmailId",
    "referenceToQuotationNumber",
    "customerName",
    "customerId",
    "contractEndDate",
    "contractStartDate",
  ];

  const response = useQuery({
    queryKey: [
      API_NAME,
      page.page,
      page.size,
      page.column,
      page.keyword,
      page.mongoQuery,
    ],
    queryFn: async () =>
      await GET(
        `/${API_NAME}?page=${page.page}&size=${page.size}&columnName=${page.column}&keyword=${page.keyword}&mongoQuery=${page.mongoQuery}`
      ),
    enabled: accessPath.view,
  });

  const status = useMutation({
    mutationFn: async (body) => await PUT("workorder", body),
  });

  const calculateParameter = (quantity, cost, discount, gst) => {
    let basePrice = (Number(quantity) || 0) * (Number(cost) || 0);
    if (discount && discount > 0) {
      const discountAmount = basePrice * (discount / 100);
      basePrice -= discountAmount;
    }
    if (gst && gst > 0) {
      const gstAmount = basePrice * (gst / 100);
      basePrice += gstAmount;
    }
    return Math.ceil(basePrice);
  };

  const columnData = form ? tableColumnParser(form.formComponents) : [];
  const parseColumn = columnData.filter(
    (component) =>
      !["dateOfOrder", "estimatedDeliveryDate"].includes(component.accessor)
  );

  const filter = parseColumn
    .map((item) => ({
      label: item.Header,
      key: item.accessor,
    }))
    .concat({
      label: "User Added Date",
      key: "createdAt",
    });

  // const statusHandler = (body) => {
  // 	status.mutate(body, {
  // 		onSuccess: async (data) => {
  // 			await queryClients.invalidateQueries(["user"]);
  // 			toast.success("User status changed");
  // 		},
  // 		onError: () => {
  // 			toast.error("Failed to change status");
  // 		},
  // 	});
  // };

  const handleStatus = (id, approvedStatus) => {
    const body = {
      _id: id,
      approved: approvedStatus,
    };
    status.mutate(body, {
      onSuccess: async (data) => {
        await queryClients.invalidateQueries(["user"]);
        toast.success("Workorder approved successfully");
      },
      onError: () => {
        toast.error("Failed to change status");
      },
    });
  };
  const additionalColumn = [
    // {
    // 	Header: "Status",
    // 	accessor: "status",
    // 	Cell: ({ row }) => {
    // 		const value = row.original;
    // 		return (
    // 			value.approved===true?"Approved":"Rejected"
    // 		);
    // 	},
    // },
    // {
    // 	Header: "Approved/Rejected By",
    // 	accessor: "approved/rejectedby",
    // 	Cell: ({ row }) => {
    // 		const value = row.original;
    // 		return (
    // 			(value.approved===true || value.approved===false)?value.approvedBy:""
    // 		);
    // 	},
    // },
    {
      Header: "View",
      accessor: "view",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <div>
            <MdRemoveRedEye
              size={"1.3em"}
              className="text-primary"
              role="button"
              onClick={() => action.onView(value)}
            />
          </div>
        );
      },
    },
    // {
    // 	Header: "Action",
    // 	accessor: "action",
    // 	Cell: ({ row }) => {
    // 		const value = row.original;

    // 		return (<>
    // 			<TableAction
    // 				path={API_NAME}
    // 				value={value}
    // 				editAction={action.onEdit}
    // 				accesspoint='workorder'
    // 				showPrintButton={false}
    // 			// PdfTemplate={() => <MyDocument data={value} />}
    // 			/>
    // 			{(value.approved !== true && value.approved !== false) && <div className='d-flex'>
    // 				<OverlayTrigger placement='top' overlay={<Tooltip>Reject</Tooltip>}>
    // 					<Button
    // 						size='sm'
    // 						className='bg-transparent border-0'
    // 						onClick={() => handleStatus(value._id, false)}>
    // 						<IoIosCloseCircle className='text-danger' size='1.7em' />
    // 					</Button>
    // 				</OverlayTrigger>
    // 				<OverlayTrigger placement='top' overlay={<Tooltip>Accept</Tooltip>}>
    // 					<Button
    // 						size='sm'
    // 						className='  border-0 bg-transparent'
    // 						onClick={() => handleStatus(value._id, true)}>
    // 						<IoIosCheckmarkCircle size='1.7em' className='text-success' />{" "}
    // 					</Button>
    // 				</OverlayTrigger>
    // 			</div>}
    // 		</>
    // 		);
    // 	},
    // },
  ];

  const columns = [
    {
      Header: (
        <span>
          Work Order <br />
          ID
        </span>
      ),
      accessor: "workOrderId",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "12ch", display: "inline-block" }}>
            {value.workOrderId}
          </span>
        );
      },
    },
    {
      Header: "Created On",
      accessor: "createdAt",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "9ch", display: "inline-block" }}>
            {value.createdAt
              ? moment(value.createdAt).format("DD/MM/YYYY")
              : "-"}
          </span>
        );
      },
    },
    {
      Header: (
        <span>
          Work Order <br />
          No
        </span>
      ),
      accessor: "workOrderNo",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ display: "inline-block" }}>{value.workOrderNo}</span>
        );
      },
    },
    {
      Header: (
        <span>
          Date of <br />
          Order
        </span>
      ),
      accessor: "dateOfOrder",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "9ch", display: "inline-block" }}>
            {value?.dateOfOrder
              ? moment(new Date(value?.dateOfOrder)).format("DD/MM/YYYY")
              : "-"}
          </span>
        );
      },
    },
    {
      Header: (
        <span>
          Quotation <br />
          ID
        </span>
      ),
      accessor: "referenceToQuotationNumber",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "12ch", display: "inline-block" }}>
            {value?.referenceToQuotationNumber}
          </span>
        );
      },
    },

    {
      Header: <span>Customer ID</span>,
      accessor: "customerId",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span
            style={{
              width: "12ch",
              display: "inline-block",
            }}
          >
            {value?.customerId}
          </span>
        );
      },
    },
    {
      Header: <span>Customer Name</span>,
      accessor: "customerName",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span
            style={{
              minWidth: "auto",
              width: "max-content",
              display: "inline-block",
              whiteSpace: "nowrap",
            }}
          >
            {value?.customerName}
          </span>
        );
      },
    },
    {
      Header: (
        <span>
          Customer <br />
          Location
        </span>
      ),
      accessor: "customerLocation",
      Cell: ({ row }) => {
        const value = row.original;
        const parts = value?.additionalCity
          ? value?.additionalCity?.split(" - ")
          : "-";
        let city;
        city = parts[1]?.trim() || parts[0]?.trim() || "";
        return city || value?.customerDetails?.city || "-";
      },
    },
    {
      Header: "Total Value",
      accessor: "totalPrice",
      Cell: ({ row }) => {
        const value = row.original;
        let total = 0;
        if (value.testsAndParameters) {
          value.testsAndParameters.map((parameter) => {
            total += calculateParameter(
              Number(parameter.quantity),
              Number(parameter.price),
              Number(parameter.discount),
              Number(parameter.gst)
            );
          });
        }
        return Number(
          calculateDiscount(
            total,
            value.discount,
            value.rateOfTaxes,
            value.shippingHandlingCosts
          )
        ).toLocaleString("en-IN");
      },
    },
    {
      Header: "ETA ",
      accessor: "estimatedDeliveryDate",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "9ch", display: "inline-block" }}>
            {value?.estimatedDeliveryDate
              ? moment(new Date(value?.estimatedDeliveryDate)).format(
                  "DD/MM/YYYY"
                )
              : "-"}
          </span>
        );
      },
    },
    {
      Header: (
        <span>
          Contract <br />
          Start <br />
          Date
        </span>
      ),
      accessor: "contractStartDate",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "9ch", display: "inline-block" }}>
            {value?.contractStartDate
              ? moment(new Date(value?.contractStartDate)).format("DD/MM/YYYY")
              : "-"}
          </span>
        );
      },
    },
    {
      Header: (
        <span>
          Contract End <br />
          Date
        </span>
      ),
      accessor: "contractEndDate",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "9ch", display: "inline-block" }}>
            {value?.contractEndDate
              ? moment(new Date(value?.contractEndDate)).format("DD/MM/YYYY")
              : "-"}
          </span>
        );
      },
    },
    ...parseColumn.filter((item) => !filterOutData.includes(item.accessor)),
  ].concat(additionalColumn);
  const rows = response.data ? response.data.response.data : [];

  const pagination = response.data
    ? response.data.response
    : { page: 0, showing: 0, total: 0 };
  console.log("columns====>", columns);
  return {
    columns,
    rows,
    loading: loading || response.isLoading,
    filter,
    pagination,
  };
}

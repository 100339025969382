import { Table, SearchBar, QueryFilter } from "Components";
import useTable from "./data/useTableData";
import { useState } from "react";
import { Row, Col, Modal, Button, Stack } from "react-bootstrap";
import MainForm from "./components/MainForm";

import useUser from "hooks/useUser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApi from "hooks/useApi";
import { toast } from "react-toastify";
import moment from "moment";
const API_NAME = "quotations";
export default function Quotations() {
  const { accessPath } = useUser("quotations");
  const [formModal, setFormModal] = useState(false);
  const { POST, PUT, GET } = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (body) =>
      edit.actionMode !== "Update"
        ? await POST(API_NAME, body)
        : await PUT(API_NAME, body),
  });

   const mutationExcel = useMutation({
      mutationFn: async () => {
        const response = await GET("quotations/export/excel", {
          responseType: "blob",
        });
        return response;
      },
      onSuccess: (data) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Quotation Data-at-${moment(new Date()).format(
            "DD-MM-YYYY (h-mm a)"
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
  
        toast.success("Exported successfully");
      },
      onError: (error) => {
        toast.error(error.message || "An error occurred");
      },
    });

  const [page, setPage] = useState({
    page: 0,
    size: 10,
    column: "",
    keywoard: "",
    mongoQuery: "",
  });

  const { columns, rows, pagination, filter, loading } = useTable({
    page,
    action: { onEdit, onView, onRevised },
  });
  const [edit, setEdit] = useState({ data: {}, state: false, actionMode: "" });
  const [view, setView] = useState({ data: {}, state: false });

  function pageChangeHandler(page, size) {
    setPage((prev) => ({ ...prev, page, size }));
  }

  function searchHandler(column, keyword) {
    setPage((prev) => ({ ...prev, column, keyword }));
  }

  const closeForm = () => {
    if (edit.state) {
      setEdit({ data: {}, state: false });
    }
    if (view.state) {
      setView({ data: {}, state: false });
    }
    setFormModal(false);
  };

  function onEdit(data) {
    setEdit({ data: data, state: true, actionMode: "Update" });
    setFormModal(true);
  }

  function onView(data) {
    setView({ data: data, state: true });
    setFormModal(true);
  }

  function onRevised(data) {
    setEdit({ data: data, state: true, actionMode: "Revised" });
    setFormModal(true);
  }

  function handleSubmit(body) {
    mutation.mutate(body, {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([API_NAME]);
        toast.success(data.message);
        closeForm();
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  }

  const exportHandler = () => {
    mutationExcel.mutate();
  };


  function UserForm() {
    if (!accessPath.create) return;

    return (
      <>
        <Modal
          backdrop="static"
          size="xl"
          show={formModal}
          onHide={() => closeForm()}
        >
          <Modal.Header closeButton>
            {edit.state ? edit.actionMode : view.state ? "View" : "Add"}{" "}
            Quotations
          </Modal.Header>
          <Modal.Body>
            <MainForm
              updatemode={edit.state}
              data={edit.state ? edit?.data : view.data}
              actionMode={edit.state ? edit.actionMode : "View"}
              closeForm={closeForm}
              onSubmit={handleSubmit}
              viewMode={view.state}
            />
          </Modal.Body>
        </Modal>
        <Stack direction="horizontal" gap={2}>
          <Button size="sm" onClick={() => setFormModal(true)}>
            Add Quotations
          </Button>
          <Button
            size="sm"
            onClick={exportHandler}
            disabled={mutation.isLoading}
          >
            {mutation.isLoading ? "Exporting..." : "Export To Excel"}
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <div>
      <Row className="mb-3">
        <Col md={9}>
          <SearchBar filter={filter} onChange={searchHandler} />
        </Col>

        <Col md={3} className="d-flex justify-content-end">
          <UserForm />
        </Col>
      </Row>

      <Table
        table={{ columns, data: rows }}
        pagination={{ ...pagination }}
        onPageChangeHandler={pageChangeHandler}
        loading={loading}
        disabled={!accessPath.view}
        showColumnsForTable={[
          "quotationsId",
          "referenceEnquiryId",
          "parentQuotation",
          "customerId",
          "customerName",
          "totalPrice",
          "quotationDateFormat",
          "itemServiceCategory",
          // "estimateddeliverydate",
          "validityDate",
          "status",
          "usedstatus",
          "view",
          "action",
          // "createdOn",
          "customerLocation",
        ]}
      />
    </div>
  );
}

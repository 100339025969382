import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CommonUtil from "util/CommonUtil";
import bodyParser from "util/bodyParser";
import { calculateDiscount } from "util/calculate";

const API_NAME = "workorder";

const EXtraMainFormComponent = ({
  updatemode,
  viewMode = false,
  data,
  closeForm,
}) => {
  const [formData, setFormData] = useState({
    totalPrice: 0,
    itemServiceCategory: "",
    testsAndParameters: [],
    paymentMethod: "",
    paymentSchedule: "",
    taxes: "",
    discount: "0",
    rateOfTaxes: "0",
    shippingHandlingCosts: "0",
    estimatedDeliveryDate: "",
    additionalCity: "",
    additionalGSTDetails: "",
    additionalNameOfContactPerson: "",
    additionalContactNo: "",
    additionalEmailId: "",
    additionalAddress: "",
  });
  const [isDisabled, setIsDisabled] = useState(viewMode);

  const [referenceToQuotationId, setReferenceToQuotationId] = useState(null);
  const [quototations, setQuotations] = useState([]);
  const [quotationDetails, setQuotationDetails] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [locationList, setLocationList] = useState([]);
  const queryClient = useQueryClient();
  const [locationDetails, setLocationDetails] = useState({});

  const { GET, PUT, POST } = useApi();

  const mutation = useMutation({
    mutationFn: updatemode
      ? async (body) => await PUT(API_NAME, body)
      : async (body) => await POST(API_NAME, body),
  });

  useEffect(() => {
    if (updatemode) {
      setFormData(data);
    }
  }, [data, updatemode]);

  const submitHandler = (data) => {
    const body = data;
    mutation.mutate(body, {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([API_NAME]);
        toast.success(data.message);
        closeForm();
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  const handleChange = (name, value, parentField = "", idx = "") => {
    if (parentField) {
      const arr = [...formData[parentField]];
      if (arr[idx]) {
        arr[idx] = {
          ...arr[idx],
          [name]: value,
        };
      } else {
        arr[idx] = {
          name: quotationDetails.testsAndParameters[idx],
          [name]: value,
        };
      }
      const total = arr.reduce((accumulator, currentValue) => {
        return (
          Number(currentValue.price) * Number(currentValue.quantity) +
          accumulator
        );
      }, 0);
      if (updatemode) {
        setFormData((prev) => ({
          ...prev,
          [parentField]: arr,
          totalPrice: total,
        }));
      }
      setFormData((prev) => ({
        ...prev,
        [parentField]: arr,
        totalPrice: total,
      }));
    } else {
      if (updatemode) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const calculateParameter = (quantity, cost, discount, gst) => {
    let basePrice = (Number(quantity) || 0) * (Number(cost) || 0);
    if (discount && discount > 0) {
      const discountAmount = basePrice * (discount / 100);
      basePrice -= discountAmount;
    }
    if (gst && gst > 0) {
      const gstAmount = basePrice * (gst / 100);
      basePrice += gstAmount;
    }
    return Math.ceil(basePrice);
  };

  const quotationList = useQuery({
    queryKey: ["referenceQuotations"],
    queryFn: async () => await GET("/quotations?status=Accept"),
    refetchOnWindowFocus: true,
  });

  const customerData = useQuery({
    queryKey: ["customerDetails", referenceToQuotationId],
    queryFn: async () =>
      await GET(
        `/quotations/customer-by-quotations?id=${referenceToQuotationId}`
      ),
    refetchOnWindowFocus: true,
    enabled: !!referenceToQuotationId,
  });

  const getOneQuotation = useQuery({
    queryKey: ["referenceQuotations", referenceToQuotationId],
    queryFn: async () =>
      await GET(`quotations/details/${referenceToQuotationId}`),
    refetchOnWindowFocus: true,
    enabled: !!referenceToQuotationId,
  });

  const locationData = useQuery({
    queryKey: ["locationData", customerDetails._id, referenceToQuotationId],
    queryFn: async () =>
      await GET(`quotations/locations/${customerDetails._id}`),
    refetchOnWindowFocus: true,
    enabled: !!customerDetails._id,
  });

  // SET QUOTATION DROPDOWN
  useEffect(() => {
    if (!quotationList.isLoading) {
      setQuotations(quotationList.data?.response?.data || []);
    }
  }, [quotationList.isLoading, quotationList.data]);

  //SET QUOTATION DETAILS
  useEffect(() => {
    if (!getOneQuotation.isLoading) {
      const data = getOneQuotation.data?.response?.data || {};
      setQuotationDetails(data);
      if (data.testsAndParameters) {
        let total = 0;
        data.testsAndParameters.map((parameter) => {
          total += calculateParameter(
            Number(parameter.quantity),
            Number(parameter.price),
            Number(parameter.discount),
            Number(parameter.gst)
          );
        });
        setFormData((prev) => ({
          ...prev,
          totalPrice: total,
          itemServiceCategory: data?.itemServiceCategory || "",
          testsAndParameters: data?.testsAndParameters || [],
          paymentMethod: data?.paymentMethod,
          paymentSchedule: data?.paymentSchedule,
          taxes: data?.taxes || "",
          discount: data?.discount || "",
          rateOfTaxes: data?.rateOfTaxes || "",
          shippingHandlingCosts: data?.shippingHandlingCosts || "",
          estimatedDeliveryDate: data?.estimatedDeliveryDate || "",
          additionalLocationId: data?.additionalLocationId || "",
        }));
        setLocationDetails({
          additionalCity: data?.additionalCity || "",
          additionalGSTDetails: data?.additionalGSTDetails || "",
          additionalNameOfContactPerson:
            data?.additionalNameOfContactPerson || "",
          additionalContactNo: data?.additionalContactNo || "",
          additionalEmailId: data?.additionalEmailId || "",
          additionalAddress: data?.additionalAddress || "",
          additionalLocationId: data?.additionalLocationId || "",
        });
      }
    }
  }, [getOneQuotation.isLoading, getOneQuotation.data]);

  // SET CUSTOMER DATA
  useEffect(() => {
    if (!customerData.isLoading) {
      const data = customerData.data?.response?.data[0]?.customerDetails || {};
      setCustomerDetails(
        customerData.data?.response?.data[0]?.customerDetails || {}
      );
      setFormData((prev) => ({
        ...prev,
        customerId: data._id,
      }));
    }
  }, [customerData.isLoading, customerData.data]);

  // SET LOCATION LIST
  useEffect(() => {
    if (!locationData.isLoading) {
      setLocationList(locationData.data?.response?.data || []);
    }
  }, [locationData.isLoading, locationData.data]);

  useEffect(() => {
    console.log("LOC DETAILS ============", locationDetails);
  }, [locationDetails, data]);

  useEffect(() => {
    if (
      !locationData.isLoading &&
      locationList.length &&
      data &&
      (viewMode || updatemode)
    ) {
      const selectedLocation = locationList.find(
        (location) =>
          location.additionalLocationId === data.additionalLocationId
      );
      selectedLocation
        ? setLocationDetails(selectedLocation)
        : setLocationDetails(data?.additionalDetails || {});
    }
  }, [locationList, viewMode, updatemode, data]);

  useEffect(() => {
    if (viewMode) {
      let total = 0;
      if (data.testsAndParameters) {
        data.testsAndParameters.map((parameter) => {
          total += calculateParameter(
            Number(parameter.quantity),
            Number(parameter.price),
            Number(parameter.discount),
            Number(parameter.gst)
          );
        });
      }
      console.log("TOTAL ==>", data.testsAndParameters);
      setFormData({ ...data, totalPrice: total });
      setQuotationDetails(data.quotationsDetails);
      setCustomerDetails(data.customerDetails);
      setIsDisabled(true);
    }
  }, [viewMode]);

  return (
    <>
      <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
        {viewMode ? (
          <div className="col-md-12 col-md-offset-0 col-md-push-0 col-md-pull-0">
            <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
              <label className="col-form-label">Work Order Id</label>
              <input
                className="form-control"
                disabled={true}
                value={formData?.workOrderId || ""}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="col-md-12 col-md-offset-0 col-md-push-0 col-md-pull-0">
          <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
            <label className="col-form-label">Customer Work Order No</label>
            <input
              className="form-control"
              name="workOrderNo"
              value={formData?.workOrderNo || ""}
              disabled={isDisabled}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
        <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
          <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
            <label className="col-form-label">Date of Order</label>
            <input
              className="form-control"
              type="date"
              name="dateOfOrder"
              disabled={isDisabled}
              value={formData?.dateOfOrder || ""}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
          <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId required">
            <label className="col-form-label field-required">
              Quotation ID
            </label>
            {viewMode ? (
              <input
                className="form-control"
                value={formData?.referenceToQuotationNumber || ""}
                disabled={isDisabled}
              />
            ) : (
              <select
                className="form-control choices__input"
                name="referenceToQuotationNumber"
                defaultValue=""
                required
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                  setReferenceToQuotationId(e.target.value);
                  setLocationList([]);
                  setFormData((prev) => ({
                    ...prev,
                    additionalCity: "",
                    additionalGSTDetails: "",
                    additionalNameOfContactPerson: "",
                    additionalContactNo: "",
                    additionalEmailId: "",
                    additionalAddress: "",
                  }));
                }}
              >
                <option value="" disabled>
                  -- Select an Quotation --
                </option>
                {!quotationList.isLoading ? (
                  quototations.map((quotation) => (
                    <option value={quotation._id}>
                      {quotation.quotationsId}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    -- Loading... --
                  </option>
                )}
              </select>
            )}
          </div>
        </div>
      </div>
      <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
        <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
          <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
            <label className="col-form-label">Contract Start Date</label>
            <input
              className="form-control"
              name="contractStartDate"
              type="date"
              value={formData?.contractStartDate || ""}
              disabled={isDisabled}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
          <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
            <label className="col-form-label">Contract End Date</label>
            <input
              className="form-control"
              name="contractEndDate"
              type="date"
              value={formData?.contractEndDate}
              disabled={isDisabled}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="formio-component formio-component-panel formio-component-customerInformation">
        <div className="mb-2 card border">
          <div className="card-header bg-default">
            <span class="mb-0 card-title">Customer Information</span>
          </div>
          <div className="card-body">
            <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Customer Id</label>
                  <input
                    className="form-control"
                    disabled={true}
                    name="customerId"
                    value={customerDetails?.customerId || ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Customer Name</label>
                  <input
                    className="form-control"
                    disabled={true}
                    name="customerName"
                    value={customerDetails?.name || ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="formio-component formio-component-panel formio-component-customerInformation">
        <div className="mb-2 card border">
          <div className="card-header bg-default">
            <span class="mb-0 card-title">Customer Location</span>
          </div>
          <div className="card-body">
            <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Location</label>
                  {viewMode ? (
                    <input
                      className="form-control"
                      value={locationDetails?.additionalCity || ""}
                      disabled
                    />
                  ) : (
                    <select
                      className="form-control choices__input"
                      //   value={formData.referenceEnquiryId || ""}
                      name="locations"
                      disabled={isDisabled}
                      defaultValue={locationDetails?.additionalLocationId || ""}
                      value={locationDetails?.additionalLocationId || ""}
                      onChange={(e) => {
                        const selectedCity = e.target.value;
                        const selectedLocation = locationList.find(
                          (location) =>
                            location.additionalLocationId === selectedCity
                        );
                        setLocationDetails(selectedLocation);
                        setFormData((prev) => ({
                          ...prev,
                          additionalLocationId:
                            selectedLocation?.additionalLocationId || "",
                        }));
                      }}
                    >
                      <option value="" disabled>
                        -- Select an Location --
                      </option>

                      {locationData.isLoading ? (
                        <option value="" disabled>
                          -- Loading... --
                        </option>
                      ) : (
                        locationList.map((location) => (
                          <option
                            key={location.additionalLocationId}
                            value={
                              location?.additionalLocationId ||
                              "--No City Selected--"
                            }
                          >
                            {" "}
                            {location?.additionalCity ||
                              "--No City Selected--"}{" "}
                          </option>
                        ))
                      )}
                    </select>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">GST IN</label>
                  <input
                    className="form-control"
                    disabled={true}
                    value={locationDetails?.additionalGSTDetails || ""}
                  />
                </div>
              </div>
            </div>
            <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">
                    Name of Contact Person
                  </label>
                  <input
                    className="form-control"
                    disabled={true}
                    value={locationDetails?.additionalNameOfContactPerson || ""}
                  />
                </div>
              </div>
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Contact No</label>
                  <input
                    className="form-control"
                    disabled={true}
                    value={locationDetails?.additionalContactNo || ""}
                  />
                </div>
              </div>
            </div>
            <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Email Id</label>
                  <input
                    className="form-control"
                    disabled={true}
                    value={locationDetails?.additionalEmailId || ""}
                  />
                </div>
              </div>
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Address</label>
                  <input
                    className="form-control"
                    disabled={true}
                    value={locationDetails?.additionalAddress || ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="formio-component formio-component-panel formio-component-descriptionOfProductsServices1">
        <div className="mb-2 card border">
          <div className="card-header bg-default">
            <span className="mb-0 card-title">
              Description of Products/Services
            </span>
          </div>
          <div className="card-body">
            <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
              <div className="col-md-12 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">
                    Item Service Category
                  </label>
                  <input
                    className="form-control"
                    disabled={true}
                    value={
                      viewMode
                        ? formData?.itemServiceCategory || ""
                        : quotationDetails?.itemServiceCategory || ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
              <div className="col-md-12 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <table>
                  {(formData?.testsAndParameters ||
                    quotationDetails?.testsAndParameters) &&
                    ((Array.isArray(formData?.testsAndParameters) &&
                      formData?.testsAndParameters.length) ||
                      Array.isArray(quotationDetails?.testsAndParameters)) && (
                      <thead>
                        <tr>
                          <th
                            className="col-form-label py-3 pr-3"
                            style={{ fontWeight: 400 }}
                          >
                            Parameter
                          </th>
                          <th
                            className="col-form-label py-3 pr-3"
                            style={{ fontWeight: 400, textAlign: "center" }}
                          >
                            HSN/SAC
                          </th>
                          <th
                            className="col-form-label py-3 pr-3"
                            style={{ fontWeight: 400, textAlign: "center" }}
                          >
                            Sample Size
                          </th>
                          <th
                            className="col-form-label py-3 pr-3"
                            style={{ fontWeight: 400, textAlign: "center" }}
                          >
                            Unit
                          </th>

                          <th
                            className="col-form-label py-3 pr-3"
                            style={{ fontWeight: 400, textAlign: "center" }}
                          >
                            No. Of Sample
                          </th>
                          <th
                            className="col-form-label py-3 pr-3"
                            style={{ fontWeight: 400, textAlign: "center" }}
                          >
                            Price
                          </th>
                          <th
                            className="col-form-label py-3 pr-3"
                            style={{
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            Discount (%)
                          </th>
                          <th
                            className="col-form-label py-3 pr-3"
                            style={{
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            Total Price
                          </th>
                          <th
                            className="col-form-label py-3 pr-3"
                            style={{
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            GST (%)
                          </th>
                        </tr>
                      </thead>
                    )}
                  {viewMode
                    ? formData?.testsAndParameters &&
                      Array.isArray(formData.testsAndParameters) &&
                      formData.testsAndParameters.map((parameter, idx) => (
                        <tbody>
                          <tr className="py-3 pr-3">
                            <td
                              className="py-3 pr-3 col-md-3 col-md-offset-0 col-md-push-0 col-md-pull-0"
                              style={{ paddingLeft: "0px" }}
                            >
                              <p style={{ marginBottom: "0px" }}>
                                {
                                  CommonUtil.breakMethodAndTestAndParameter(
                                    parameter.name
                                  ).testAndParameter
                                }{" "}
                                ||{" "}
                                {
                                  CommonUtil.breakMethodAndTestAndParameter(
                                    parameter.name
                                  ).method
                                }{" "}
                              </p>
                            </td>

                            <td className="py-3 pr-3 col-md-2 col-md-offset-0 col-md-push-0 col-md-pull-0">
                              <input
                                className="form-control"
                                name="hsnSac"
                                value={
                                  formData.testsAndParameters?.[idx]?.hsnSac ||
                                  ""
                                }
                                disabled={true}
                                onChange={(e) =>
                                  handleChange(
                                    "hsnSac",
                                    e.target.value,
                                    "testsAndParameters",
                                    idx
                                  )
                                }
                              />
                            </td>

                            <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                              <input
                                className="form-control"
                                name="sampleSize"
                                value={
                                  formData.testsAndParameters?.[idx]
                                    ?.sampleSize || ""
                                }
                                disabled={true}
                                onChange={(e) =>
                                  handleChange(
                                    "sampleSize",
                                    e.target.value,
                                    "testsAndParameters",
                                    idx
                                  )
                                }
                              />
                            </td>

                            <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                              <input
                                className="form-control"
                                value={
                                  formData.testsAndParameters?.[idx]?.unit || ""
                                }
                                disabled={true}
                                onChange={(e) =>
                                  handleChange(
                                    "unit",
                                    e.target.value,
                                    "testsAndParameters",
                                    idx
                                  )
                                }
                              />
                            </td>

                            <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                              <input
                                className="form-control"
                                value={
                                  formData.testsAndParameters?.[idx]?.quantity
                                }
                                defaultValue={parameter?.quantity || ""}
                                disabled={isDisabled}
                                onChange={(e) =>
                                  handleChange(
                                    "quantity",
                                    e.target.value,
                                    "testsAndParameters",
                                    idx
                                  )
                                }
                              />
                            </td>

                            <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                              <input
                                className="form-control"
                                value={
                                  Number(
                                    formData.testsAndParameters?.[idx]?.price
                                  ).toLocaleString("en-IN") || ""
                                }
                                disabled={isDisabled}
                                defaultValue={parameter?.price || ""}
                                onChange={(e) =>
                                  handleChange(
                                    "price",
                                    e.target.value.replace(/,/g, ""),
                                    "testsAndParameters",
                                    idx
                                  )
                                }
                              />
                            </td>

                            <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                              <input
                                className="form-control"
                                value={
                                  Number(
                                    formData.testsAndParameters?.[idx]?.discount
                                  ).toLocaleString("en-IN") || ""
                                }
                                disabled={isDisabled}
                                defaultValue={parameter?.discount || ""}
                                onChange={(e) =>
                                  handleChange(
                                    "discount",
                                    e.target.value.replace(/,/g, ""),
                                    "testsAndParameters",
                                    idx
                                  )
                                }
                              />
                            </td>

                            <td className="py-3 pr-3 col-md-1.5 col-md-offset-0 col-md-push-0 col-md-pull-0">
                              <input
                                className="form-control"
                                value={calculateParameter(
                                  formData.testsAndParameters?.[idx]?.quantity,
                                  formData.testsAndParameters?.[idx]?.price,
                                  formData.testsAndParameters?.[idx]?.discount,
                                  formData.testsAndParameters?.[idx]?.gst,
                                ).toLocaleString("en-IN")}
                                disabled={true}
                              />
                            </td>

                            <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                              <input
                                className="form-control"
                                value={
                                  Number(
                                    formData.testsAndParameters?.[idx]?.gst
                                  ).toLocaleString("en-IN") || ""
                                }
                                disabled={isDisabled}
                                defaultValue={parameter?.gst || ""}
                                onChange={(e) =>
                                  handleChange(
                                    "gst",
                                    e.target.value.replace(/,/g, ""),
                                    "testsAndParameters",
                                    idx
                                  )
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      ))
                    : quotationDetails?.testsAndParameters &&
                      Array.isArray(quotationDetails.testsAndParameters) &&
                      quotationDetails.testsAndParameters.map(
                        (parameter, idx) => (
                          <tbody>
                            <tr className="py-3 pr-3">
                              <td
                                className="py-3 pr-3 col-md-3 col-md-offset-0 col-md-push-0 col-md-pull-0"
                                style={{ paddingLeft: "0px" }}
                              >
                                <p style={{ marginBottom: "0px" }}>
                                  {
                                    CommonUtil.breakMethodAndTestAndParameter(
                                      parameter.name
                                    ).testAndParameter
                                  }{" "}
                                  ||{" "}
                                  {
                                    CommonUtil.breakMethodAndTestAndParameter(
                                      parameter.name
                                    ).method
                                  }{" "}
                                </p>
                              </td>
                              <td className="py-3 pr-3 col-md-2 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                <input
                                  className="form-control"
                                  name="hsnSac"
                                  value={
                                    formData.testsAndParameters?.[idx]
                                      ?.hsnSac || ""
                                  }
                                  disabled={true}
                                  onChange={(e) =>
                                    handleChange(
                                      "hsnSac",
                                      e.target.value,
                                      "testsAndParameters",
                                      idx
                                    )
                                  }
                                />
                              </td>
                              <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                <input
                                  className="form-control"
                                  name="sampleSize"
                                  value={
                                    formData.testsAndParameters?.[idx]
                                      ?.sampleSize || ""
                                  }
                                  disabled={true}
                                  onChange={(e) =>
                                    handleChange(
                                      "sampleSize",
                                      e.target.value,
                                      "testsAndParameters",
                                      idx
                                    )
                                  }
                                />
                              </td>
                              <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                <input
                                  className="form-control"
                                  value={
                                    formData.testsAndParameters?.[idx]?.unit ||
                                    ""
                                  }
                                  disabled={true}
                                  onChange={(e) =>
                                    handleChange(
                                      "unit",
                                      e.target.value,
                                      "testsAndParameters",
                                      idx
                                    )
                                  }
                                />
                              </td>
                              <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                <input
                                  className="form-control"
                                  value={
                                    formData.testsAndParameters?.[idx]?.quantity
                                  }
                                  defaultValue={parameter?.quantity || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      "quantity",
                                      e.target.value,
                                      "testsAndParameters",
                                      idx
                                    )
                                  }
                                />
                              </td>
                              <td className="py-3 pr-3 col-md-1  col-md-offset-0 col-md-push-0 col-md-pull-0">
                                <input
                                  className="form-control"
                                  value={
                                    Number(
                                      formData.testsAndParameters?.[idx]?.price
                                    )
                                      ? Number(
                                          formData.testsAndParameters?.[idx]
                                            ?.price
                                        ).toLocaleString("en-IN")
                                      : ""
                                  }
                                  defaultValue={parameter?.price || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      "price",
                                      e.target.value.replace(/,/g, ""),
                                      "testsAndParameters",
                                      idx
                                    )
                                  }
                                />
                              </td>
                              <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                <input
                                  className="form-control"
                                  value={
                                    formData.testsAndParameters?.[idx]?.discount
                                  }
                                  defaultValue={parameter?.discount || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      "discount",
                                      e.target.value,
                                      "testsAndParameters",
                                      idx
                                    )
                                  }
                                />
                              </td>
                              <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                <input
                                  className="form-control"
                                  value={
                                    Number(
                                      calculateParameter(
                                        formData.testsAndParameters?.[idx]
                                          ?.quantity,
                                        formData.testsAndParameters?.[idx]
                                          ?.price
                                      )
                                    )
                                      ? Number(
                                          calculateParameter(
                                            formData.testsAndParameters?.[idx]
                                              ?.quantity,
                                            formData.testsAndParameters?.[idx]
                                              ?.price
                                          )
                                        ).toLocaleString("en-IN")
                                      : ""
                                  }
                                  disabled={true}
                                />
                              </td>
                              <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                <input
                                  className="form-control"
                                  value={
                                    formData.testsAndParameters?.[idx]?.gst
                                  }
                                  defaultValue={parameter?.gst || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      "gst",
                                      e.target.value,
                                      "testsAndParameters",
                                      idx
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          </tbody>
                        )
                      )}
                </table>
                <div className="formio-errors invalid-feedback"></div>
              </div>
            </div>
            <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Sub Total</label>
                  <input
                    className="form-control"
                    disabled={true}
                    value={
                      Number(formData?.totalPrice).toLocaleString("en-IN") || ""
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Discount</label>
                  <input
                    className="form-control"
                    value={
                      Number(formData?.discount).toLocaleString("en-IN") || ""
                    }
                    name="discount"
                    disabled={viewMode}
                    onChange={(e) =>
                      handleChange(
                        e.target.name,
                        e.target.value.replace(/,/g, "")
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Taxes</label>
                  <select
                    className="form-control choices__input"
                    value={formData?.taxes || ""}
                    disabled={viewMode}
                    name="taxes"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  >
                    <option value="" disabled>
                      -- Select a tax method --
                    </option>
                    {/* <option value="VAT">VAT</option> */}
                    <option value="GST">GST</option>
                  </select>
                </div>
              </div>
              {/* <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Rate of Taxes</label>
                  <input
                    className="form-control"
                    value={Number(formData?.rateOfTaxes) || ""}
                    disabled={viewMode}
                    name="rateOfTaxes"
                    onChange={(e) =>
                      handleChange(
                        e.target.name,
                        e.target.value.replace(/,/g, "")
                      )
                    }
                  />
                </div>
              </div> */}
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div className="form-group has-feedback formio-component formio-component-textarea formio-component-quotationDetails">
                  <label className="col-form-label">
                    Shipping/Handling Costs
                  </label>
                  <input
                    className="form-control"
                    value={
                      Number(formData?.shippingHandlingCosts).toLocaleString(
                        "en-IN"
                      ) || ""
                    }
                    name="shippingHandlingCosts"
                    disabled={viewMode}
                    onChange={(e) =>
                      handleChange(
                        e.target.name,
                        e.target.value.replace(/,/g, "")
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Final Ammount</label>
                  <input
                    className="form-control"
                    value={
                      Number(
                        calculateDiscount(
                          formData.totalPrice || 0,
                          formData.discount || 0,
                          formData.rateOfTaxes || 0,
                          formData.shippingHandlingCosts || 0
                        )
                      ).toLocaleString("en-IN") || ""
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="formio-component formio-component-panel formio-component-customerInformation">
        <div className="mb-2 card border">
          <div className="card-header bg-default">
            <span class="mb-0 card-title">Payment Terms</span>
          </div>
          <div className="card-body">
            <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Payment Method</label>
                  {/* <input
                    className="form-control"
                    disabled={isDisabled}
                    name="paymentMethod"
                    value={formData?.paymentMethod || ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  /> */}
                   <select
                        className="form-control choices__input"
                        value={formData.paymentMethod}
                        disabled={isDisabled}
                        name="paymentMethod"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                      >
                        <option value="" disabled>
                          -- Select a payment method --
                        </option>
                        <option value="Debit Card">Debit Card</option>
                        <option value="Credit Card">Credit Card</option>
                        <option value="UPI">UPI</option>
                        <option value="Cash">Cash</option>
                      </select>
                </div>
              </div>
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">Payment Schedule</label>
                  <input
                    className="form-control"
                    disabled={isDisabled}
                    name="paymentSchedule"
                    value={formData?.paymentSchedule || ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="formio-component formio-component-panel formio-component-customerInformation">
        <div className="mb-2 card border">
          <div className="card-header bg-default">
            <span class="mb-0 card-title">Delivery Information</span>
          </div>
          <div className="card-body">
            <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">
                    Estimated Delivery Date
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    disabled={isDisabled}
                    value={formData?.estimatedDeliveryDate || ""}
                    name="estimatedDeliveryDate"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">
                    Result Delivery Instructions
                  </label>
                  <input
                    className="form-control"
                    disabled={isDisabled}
                    value={formData?.resultDeliveryInstructions || ""}
                    name="resultDeliveryInstructions"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
              <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                  <label className="col-form-label">
                    Result Sharing Method
                  </label>
                  <input
                    className="form-control"
                    disabled={isDisabled}
                    value={formData?.resultSharingMethod || ""}
                    name="resultSharingMethod"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
        <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
          <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
            <label className="col-form-label">
              Special Instructions or Requests
            </label>
            <input
              className="form-control"
              name="specialInstructionsOrRequests"
              disabled={isDisabled}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={formData?.specialInstructionsOrRequests || ""}
            />
          </div>
        </div>
        <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
          <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
            <label className="col-form-label">Other Terms and Conditions</label>
            <input
              className="form-control"
              name="otherTermsAndConditions"
              disabled={isDisabled}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={formData?.otherTermsAndConditions || ""}
            />
          </div>
        </div>
      </div>
      <div className="form-group has-feedback formio-component formio-component-textarea formio-component-quotationDetails">
        {viewMode ? (
          ""
        ) : (
          <button
            class="btn btn-primary btn-md"
            type="submit"
            onClick={() => submitHandler(formData)}
          >
            Submit
          </button>
        )}
      </div>
    </>
  );
};

export default EXtraMainFormComponent;

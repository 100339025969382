import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from "@react-pdf/renderer";
import { useState } from "react";
import { Modal, Button, Stack } from "react-bootstrap";
import { MdPrint } from "react-icons/md";
import signPerformaInvoice from "./../../../images/static_sign_peerforma_invoice.png";
import moment from "moment";
import CommonUtil from "util/CommonUtil";

// Registering the font and its bold variant
// Font.register({
// 	family: 'Roboto',
// 	fonts: [
// 		{ src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf' }, // regular
// 		{ src: 'https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc4.ttf', fontWeight: 'bold' }, // bold
// 	]
// });
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    border: "1px solid black",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  boldText: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    // textAlign: 'center',
  },
  header: {
    // backgroundColor: "lightblue",
    height: "13mm",
    textAlign: "center",
    borderBottom: "2.5px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: "700",
  },
  formHeader: {
    fontSize: 20,
    marginTop: 5,
    marginBottom: 5,
    paddingBottom: 5,
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
  },
  form: {
    flexDirection: "row",
  },
  formItem: {
    width: 200,
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1.2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    // minHeight:40
  },
  tableCol: {
    // width: "25%",  // Adjust this value for each column width
    borderStyle: "solid",
    borderWidth: 1.2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  tableCell: {
    margin: 5,
    fontSize: 8,
  },
  headerCell: {
    fontWeight: "bold",
    fontSize: 8,
    margin: 5,
  },
  tableLastColumnBorderZero: {
    borderRightWidth: 0,
  },
  alignItemsEnd: {
    alignItems: "flex-end",
  },
});

const stateCodes = {
  "JAMMU AND KASHMIR": "01",
  "HIMACHAL PRADESH": "02",
  PUNJAB: "03",
  CHANDIGARH: "04",
  UTTARAKHAND: "05",
  HARYANA: "06",
  DELHI: "07",
  RAJASTHAN: "08",
  "UTTAR PRADESH": "09",
  BIHAR: "10",
  SIKKIM: "11",
  "ARUNACHAL PRADESH": "12",
  NAGALAND: "13",
  MANIPUR: "14",
  MIZORAM: "15",
  TRIPURA: "16",
  MEGHALAYA: "17",
  ASSAM: "18",
  "WEST BENGAL": "19",
  JHARKHAND: "20",
  ODISHA: "21",
  CHATTISGARH: "22",
  "MADHYA PRADESH": "23",
  GUJARAT: "24",
  "DADRA AND NAGAR HAVELI AND DAMAN AND DIU (NEWLY MERGED UT)": "26",
  MAHARASHTRA: "27",
  "ANDHRA PRADESH(BEFORE DIVISION)": "28",
  KARNATAKA: "29",
  GOA: "30",
  LAKSHADWEEP: "31",
  KERALA: "32",
  "TAMIL NADU": "33",
  PUDUCHERRY: "34",
  "ANDAMAN AND NICOBAR ISLANDS": "35",
  TELANGANA: "36",
  "ANDHRA PRADESH (NEWLY ADDED)": "37",
  "LADAKH (NEWLY ADDED)": "38",
  "OTHER TERRITORY": "97",
  "CENTRE JURISDICTION": "99",
};

function getStateCode(stateName) {
  const code = stateCodes[stateName.toUpperCase()]; // Convert to uppercase for case-insensitive lookup
  if (code) {
    return code;
  } else {
    return "State not found"; // Return a message if the state is not in the list
  }
}
function calculateDiscount(rate = 0, quantity = 0, discount = 0) {
  // Calculate the discounted price
  const totalPrice = Number(rate) * Number(quantity);
  const discountedPrice = totalPrice - totalPrice * (Number(discount) / 100);
  // Return the final discounted price
  return discountedPrice.toFixed(2);
}

function calulateGST(arr, rate) {
  let total = 0;
  arr.forEach((item) => {
    const value = calculateDiscount(item.rate, item.quantity, item.discount);
    total = total + Number(value);
  });

  const execAmmount = total * (rate / 100);
  return execAmmount.toFixed(2);
}

const calculateTotal = (arr) => {
  let total = 0;
  arr.forEach((item) => {
    const value = calculateDiscount(
      item.rate,
      item.quantity,
      item.discount ? item.discount : 0
    );
    total = total + Number(value);
  });
  const execAmmount = (total * (9 / 100)).toFixed(2);
  return (Number(total) + 2 * execAmmount).toFixed(2);
};

const caculateWithoutGST = (arr) => {
  let total = 0;
  arr.forEach((item) => {
    const value = calculateDiscount(item.rate, item.quantity, item.discount);
    total = total + Number(value);
  });
  return total.toFixed(2);
};

function numberToWordsInIndianCurrency(num) {
  const belowTwenty = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const thousands = ["", "Thousand", "Lakh", "Crore"];

  function helper(n) {
    if (n < 20) {
      return belowTwenty[n];
    } else if (n < 100) {
      return (
        tens[Math.floor(n / 10)] + (n % 10 ? " " + belowTwenty[n % 10] : "")
      );
    } else if (n < 1000) {
      return (
        belowTwenty[Math.floor(n / 100)] +
        " Hundred" +
        (n % 100 ? " " + helper(n % 100) : "")
      );
    } else if (n < 100000) {
      return (
        helper(Math.floor(n / 1000)) +
        " Thousand" +
        (n % 1000 ? " " + helper(n % 1000) : "")
      );
    } else if (n < 10000000) {
      return (
        helper(Math.floor(n / 100000)) +
        " Lakh" +
        (n % 100000 ? " " + helper(n % 100000) : "")
      );
    } else {
      return (
        helper(Math.floor(n / 10000000)) +
        " Crore" +
        (n % 10000000 ? " " + helper(n % 10000000) : "")
      );
    }
  }

  function convertDecimalPart(decimalStr) {
    let decimalNumber = parseInt(decimalStr);
    if (decimalNumber === 0) return "Zero";
    return helper(decimalNumber);
  }

  // Split the number into integer and decimal parts
  let parts = num.toString().split(".");
  let integerPart = parseInt(parts[0]);
  let decimalPart = parts[1] ? parts[1].slice(0, 2) : null; // limit to 2 decimal places if exists

  let result = helper(integerPart) + " Rupees";

  if (decimalPart) {
    result += " and " + convertDecimalPart(decimalPart) + " Paise";
  }

  return result;
}

function getCurrentFinancialYear() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth(); // 0 = January, 11 = December

  // In most financial systems, the fiscal year starts in April, so we adjust accordingly
  let startYear, endYear;

  if (currentMonth >= 3) {
    // If the current month is April (index 3) or later, it's the current year and the next year
    startYear = currentYear;
    endYear = currentYear + 1;
  } else {
    // If the current month is January, February, or March, it's the previous year and the current year
    startYear = currentYear - 1;
    endYear = currentYear;
  }

  // Return the year structure as "YYYY-YY"
  return `${startYear}-${endYear.toString().slice(-2)}`;
}

function getTodayDate() {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0"); // Get day and add leading zero if needed
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
  const year = today.getFullYear(); // Get the full year

  return `${day}/${month}/${year}`;
}

function formatDateInDDMMYYYY(inputDate) {
  // Create a Date object from the input
  const date = new Date(inputDate);

  // Check if the date is invalid
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date format");
  }

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Return the formatted date
  return `${day}/${month}/${year}`;
}

const MyDocument = ({ data, pdf, onClose }) => {
  // const [pdf, setPdf] = useState(false);
  const value = data;
  console.log("value=====================>", value);
  const arr = [0, 1, 2, 3];
  const fieldName = [
    "description",
    "commodityName",
    "hsnSac",
    "quantity",
    "rate",
    "per",
    "discount",
  ];
  const billTo = {};
  const shipTo = {};
  billTo["companyName"] = value["customerName"];
  billTo["companyAddress"] = value["customerAddress"];
  billTo["gstIn"] = value["customerGSTDetails"];
  const billToGstData = CommonUtil.getStateNameAndGSTNumber(
    value["customerGSTDetails"]
  );
  billTo["stateName"] = billToGstData?.name || "Invalid GST Number";
  billTo["stateCode"] = billToGstData?.gst_code || "Invalid GST Number";
  billTo["emailId"] = value["customerEmail"];

  shipTo["companyName"] = value["customerName"];
  shipTo["companyAddress"] = value["additionalAddress"];
  shipTo["gstIn"] = value["additionalGSTDetails"];
  const shipToGstData = CommonUtil.getStateNameAndGSTNumber(
    value["additionalGSTDetails"]
  );
  shipTo["stateName"] = shipToGstData?.name || "Invalid GST Number";
  shipTo["stateCode"] = shipToGstData?.gst_code || "Invalid GST Number";
  shipTo["emailId"] = value["additionalEmailId"];
  const itemArray = [];
  arr.forEach((item) => {
    let obj = null;
    let bool = false;
    fieldName.forEach((nestedItem, index) => {
      if (item === 0) {
        if (value[nestedItem]) {
          obj = {
            ...obj,
            [nestedItem]: value[nestedItem],
          };
          // bool=false;
        }
      } else {
        if (value[`${nestedItem}${item}`]) {
          obj = {
            ...obj,
            [nestedItem]: value[`${nestedItem}${item}`],
          };
          // bool=false;
        }
      }
    });
    if (obj) {
      itemArray.push(obj);
    }
  });

  const PDF = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ border: "2.5px solid black", height: "78.5%" }}>
          <View style={styles.header}>
            <Text style={{}}>QUOTATION - {value.quotationsId}</Text>
          </View>
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderBottom: "1.2px solid black",
            }}
          >
            <View style={{ width: "40%", height: "180px" }}>
              <View
                style={{
                  borderBottom: "1.2px solid black",
                  padding: "5px 5px",
                  lineHeight: "1.5px",
                }}
              >
                <Text style={{ fontSize: "8px" }}>
                  TRUEWORTHS MINERALS SOLUTIONS PVT. LTD.
                </Text>
                <Text style={{ fontSize: "8px", lineHeight: "1.3px" }}>
                  1st Floor, Flat No. 102, Nandlok Apartment, Joraphatak Road,
                  Dhanbad, Jharkhand - 826001
                </Text>
                <Text style={{ fontSize: "8px", marginTop: "4px" }}>
                  GSTIN: 20AAJCT6877G1Z3
                </Text>
                <Text style={{ fontSize: "8px" }}>
                  State Name: Jharkhand Code: 20
                </Text>
                <Text style={{ fontSize: "8px" }}>
                  Email Id: corporate@trueworths.in{" "}
                </Text>
              </View>
              <View
                style={{
                  padding: "5px 5px",
                  lineHeight: "1.5px",
                  borderBottom: "1.2px solid black",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <View>
                    <Text style={{ fontSize: "8px" }}>Approver:</Text>
                    <Text style={{ fontSize: "8px" }}>
                      Name:
                      {value?.approverName}
                    </Text>
                    <Text style={{ fontSize: "8px" }}>
                      Designation: {value?.approverRole}
                    </Text>
                  </View>
                  <View>
                    <Text style={{ fontSize: "8px" }}>Customer:</Text>
                    <Text style={{ fontSize: "8px" }}>
                      Name:
                      {value?.customerName}
                    </Text>
                    <Text style={{ fontSize: "8px" }}>
                      Customer ID: {value?.customerId}
                    </Text>

                    {/* <Text style={{ fontSize: "8px" }}>
                  GSTIN:
                  {billTo?.gstIn}
                </Text>
                <Text style={{ fontSize: "8px" }}>
                  State Name: 
				  {billTo?.stateName}
				   Code:
                  {getStateCode(billTo?.stateName.toUpperCase())}
                </Text>
                <Text style={{ fontSize: "8px" }}>
                  Email Id: 
				  {billTo?.emailId}
                </Text> */}
                  </View>
                </View>
              </View>
              <View style={{ padding: "5px 5px", lineHeight: "1.5px" }}>
                <Text style={{ fontSize: "8px" }}>Bill To:</Text>
                <Text style={{ fontSize: "8px" }}>
                  Company Name:
                  {billTo?.companyName}
                </Text>
                <Text style={{ fontSize: "8px" }}>
                  Address:
                  {billTo?.companyAddress}
                </Text>
                <Text style={{ fontSize: "8px" }}>GSTIN: {billTo?.gstIn}</Text>
                <Text style={{ fontSize: "8px" }}>
                  State Name:
                  {billTo?.stateName} ---
                  Code: {billTo?.stateCode}
                </Text>
                <Text style={{ fontSize: "8px" }}>
                  Email Id: {billTo?.emailId}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "20%",
                height: "180px",
                borderLeft: "1.2px solid black",
                borderRight: "1.2px solid black",
              }}
            ></View>
            <View style={{ width: "40%", height: "180px" }}>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  borderBottom: "1.2px solid black",
                }}
              >
                <View
                  style={{
                    width: "50%",
                    padding: "5px 5px",
                    lineHeight: "1.5px",
                  }}
                >
                  <Text style={{ fontSize: "8px" }}>Quotation No.:</Text>
                  <Text style={{ fontSize: "8px" }}>
                    {/* {getCurrentFinancialYear()}/ */}
                    {/* {value.invoice.split("_").pop()} */}
                    {value?.quotationsId}
                  </Text>
                </View>
                <View
                  style={{
                    width: "50%",
                    padding: "5px 5px",
                    lineHeight: "1.5px",
                    borderLeft: "1.2px solid black",
                  }}
                >
                  {/* <Text style={{ fontSize: "8px" }}> PDF Generated Date:</Text>
                  <Text style={{ fontSize: "8px" }}>{getTodayDate()}</Text> */}
                  <Text style={{ fontSize: "8px" }}>
                    Quotation Created On:{" "}
                  </Text>
                  <Text style={{ fontSize: "8px" }}>
                    {moment(value.createdAt).format("DD/MM/YYYY (h:mm a)")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  padding: "3px 5px",
                  borderBottom: "1.2px solid black",
                }}
              >
                <Text style={{ fontSize: "8px" }}>
                  Mode & Schedule of payment : {value?.paymentMethod} &{" "}
                  {value?.paymentSchedule}
                </Text>
              </View>
              <View
                style={{
                  padding: "3px 5px",
                  borderBottom: "1.2px solid black",
                }}
              >
                <Text style={{ fontSize: "8px" }}>
                  Reference No. & Date:{" "}
                  {value.referenceEnquiryId +
                    " & " +
                    moment(value.referenceEnquiryCreatedAt).format(
                      "DD/MM/YYYY (h:mm a)"
                    )}
                </Text>
              </View>
              <View
                style={{
                  padding: "3px 5px",
                  borderBottom: "1.2px solid black",
                }}
              >
                <Text style={{ fontSize: "8px" }}>
                  Terms of Delivery:
                  {value.deliveryTerms}
                </Text>
              </View>
              <View
                style={{
                  padding: "3px 5px",
                  borderBottom: "1.2px solid black",
                }}
              >
                <Text style={{ fontSize: "8px" }}>
                  Other Terms & Condition: {value?.otherTermsAndConditions}
                </Text>
              </View>
              <View style={{ padding: "5px 5px", lineHeight: "1.5px" }}>
                <Text style={{ fontSize: "8px" }}>Ship To:</Text>
                <Text style={{ fontSize: "8px" }}>
                  Company Name:
                  {shipTo?.companyName}
                </Text>
                <Text style={{ fontSize: "8px" }}>
                  Address:
                  {shipTo?.companyAddress}
                </Text>
                <Text style={{ fontSize: "8px" }}>GSTIN: {shipTo?.gstIn}</Text>
                <Text style={{ fontSize: "8px" }}>
                  State Name:
                  {shipTo?.stateName} ---
                  Code: {shipTo?.stateCode}
                </Text>
                <Text style={{ fontSize: "8px" }}>
                  Email Id: {shipTo?.emailId}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={[styles.headerCell]}>Sl. No.</Text>
              </View>
              <View style={[styles.tableCol, { width: "25.5%" }]}>
                <Text style={[styles.headerCell]}>
                  Description of Goods/Services
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={[styles.headerCell]}>Commodity Name</Text>
              </View>
              <View style={[styles.tableCol, { width: "15%" }]}>
                <Text style={[styles.headerCell]}>HSN/SAC</Text>
              </View>
              <View style={[styles.tableCol, { width: "15%" }]}>
                <Text style={[styles.headerCell]}>Quantity</Text>
              </View>
              <View style={[styles.tableCol, { width: "15%" }]}>
                <Text style={[styles.headerCell]}>Rate</Text>
              </View>
              <View style={[styles.tableCol, { width: "12%" }]}>
                <Text style={[styles.headerCell]}>Per</Text>
              </View>
              <View style={[styles.tableCol, { width: "8%" }]}>
                <Text style={[styles.headerCell]}>Disc. %</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  styles.tableLastColumnBorderZero,
                  { width: "12%" },
                ]}
              >
                <Text style={[styles.headerCell]}>Amount (Rs.)</Text>
              </View>
            </View>
            {itemArray?.map((item, idx) => {
              return (
                <>
                  <View key={idx + 1} style={styles.tableRow}>
                    <View style={[styles.tableCol, { width: "5%" }]}>
                      <Text style={styles.tableCell}>{idx + 1}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "25.5%" }]}>
                      <Text style={[styles.tableCell]}>
                        {item?.description}
                      </Text>
                    </View>
                    <View style={[styles.tableCol, { width: "10%" }]}>
                      <Text style={[styles.tableCell]}>
                        {item?.commodityName}
                      </Text>
                    </View>
                    <View style={[styles.tableCol, { width: "15%" }]}>
                      <Text style={[styles.tableCell]}>{item?.hsnSac}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "15%" }]}>
                      <Text style={[styles.tableCell]}>{item?.quantity}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "15%" }]}>
                      <Text style={[styles.tableCell]}>{item?.rate}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "12%" }]}>
                      <Text style={[styles.tableCell]}>{item?.per}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "8%" }]}>
                      <Text style={[styles.tableCell]}>{item?.discount}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCol,
                        styles.tableLastColumnBorderZero,
                        { width: "12%" },
                      ]}
                    >
                      <Text style={styles.tableCell}>
                        {calculateDiscount(
                          item?.rate,
                          item?.quantity,
                          item?.discount
                        )}
                      </Text>
                    </View>
                  </View>
                </>
              );
            })}

            {/* Table Row 1 */}

            {value.taxType === "cgstAndSgst" ? (
              <>
                <View style={styles.tableRow}>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "5%", borderBottomWidth: 0 },
                    ]}
                  >
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      styles.alignItemsEnd,
                      { width: "35.5%" },
                    ]}
                  >
                    <Text style={[styles.tableCell]}>CGST@9%</Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "15%",
                        borderBottomWidth: 0,
                        borderRightWidth: 0,
                      },
                    ]}
                  >
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "15%",
                        borderBottomWidth: 0,
                        borderRightWidth: 0,
                      },
                    ]}
                  >
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "15%",
                        borderBottomWidth: 0,
                        borderRightWidth: 0,
                      },
                    ]}
                  >
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "12%",
                        borderBottomWidth: 0,
                        borderRightWidth: 0,
                      },
                    ]}
                  >
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "8%", borderBottomWidth: 0 },
                    ]}
                  >
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      styles.tableLastColumnBorderZero,
                      { width: "12%" },
                    ]}
                  >
                    <Text style={styles.tableCell}>
                      {calulateGST(itemArray, 9)}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "5%", borderBottomWidth: 0 },
                    ]}
                  >
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      styles.alignItemsEnd,
                      { width: "35.5%" },
                    ]}
                  >
                    <Text style={[styles.tableCell]}>SGST@9%</Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "15%",
                        borderBottomWidth: 0,
                        borderRightWidth: 0,
                      },
                    ]}
                  >
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "15%",
                        borderBottomWidth: 0,
                        borderRightWidth: 0,
                      },
                    ]}
                  >
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "15%",
                        borderBottomWidth: 0,
                        borderRightWidth: 0,
                      },
                    ]}
                  >
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "12%",
                        borderBottomWidth: 0,
                        borderRightWidth: 0,
                      },
                    ]}
                  >
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "8%", borderBottomWidth: 0 },
                    ]}
                  >
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      styles.tableLastColumnBorderZero,
                      { width: "12%" },
                    ]}
                  >
                    <Text style={styles.tableCell}>
                      {calulateGST(itemArray, 9)}
                    </Text>
                  </View>
                </View>
              </>
            ) : (
              <View style={styles.tableRow}>
                <View
                  style={[
                    styles.tableCol,
                    { width: "5%", borderBottomWidth: 0 },
                  ]}
                >
                  <Text style={styles.tableCell}></Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    styles.alignItemsEnd,
                    { width: "35.5%" },
                  ]}
                >
                  <Text style={[styles.tableCell]}>IGST@18%</Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: "15%", borderBottomWidth: 0, borderRightWidth: 0 },
                  ]}
                >
                  <Text style={[styles.tableCell]}></Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: "15%", borderBottomWidth: 0, borderRightWidth: 0 },
                  ]}
                >
                  <Text style={[styles.tableCell]}></Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: "15%", borderBottomWidth: 0, borderRightWidth: 0 },
                  ]}
                >
                  <Text style={[styles.tableCell]}></Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: "12%", borderBottomWidth: 0, borderRightWidth: 0 },
                  ]}
                >
                  <Text style={[styles.tableCell]}></Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: "8%", borderBottomWidth: 0 },
                  ]}
                >
                  <Text style={[styles.tableCell]}></Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    styles.tableLastColumnBorderZero,
                    { width: "12%" },
                  ]}
                >
                  <Text style={styles.tableCell}>
                    {calulateGST(itemArray, 18)}
                  </Text>
                </View>
              </View>
            )}
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  styles.alignItemsEnd,
                  { width: "35.5%" },
                ]}
              >
                <Text style={[styles.tableCell]}>Total</Text>
              </View>
              <View
                style={[styles.tableCol, { width: "15%", borderRightWidth: 0 }]}
              >
                <Text style={[styles.tableCell]}></Text>
              </View>
              <View
                style={[styles.tableCol, { width: "15%", borderRightWidth: 0 }]}
              >
                <Text style={[styles.tableCell]}></Text>
              </View>
              <View
                style={[styles.tableCol, { width: "15%", borderRightWidth: 0 }]}
              >
                <Text style={[styles.tableCell]}></Text>
              </View>
              <View
                style={[styles.tableCol, { width: "12%", borderRightWidth: 0 }]}
              >
                <Text style={[styles.tableCell]}></Text>
              </View>
              <View style={[styles.tableCol, { width: "8%" }]}>
                <Text style={[styles.tableCell]}></Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  styles.tableLastColumnBorderZero,
                  { width: "12%" },
                ]}
              >
                <Text style={styles.tableCell}>
                  {calculateTotal(itemArray)}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              fontSize: 8,
              padding: "5px 5px",
              width: "100%",
              height: 40,
              display: "flex",
              flexDirection: "row",
              borderBottom: "1.2px solid black",
              position: "relative",
              alignItems: "center",
            }}
          >
            <Text style={{}}>
              Amount Chargeable (in words):
              {numberToWordsInIndianCurrency(calculateTotal(itemArray))}
            </Text>
            <Text
              style={{ position: "absolute", top: 5, right: 80, width: 40 }}
            >
              E. & O.E.
            </Text>
          </View>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "37%" }]}>
                <Text style={[styles.headerCell]}></Text>
              </View>
              <View style={[styles.tableCol, { width: "12%" }]}>
                <Text style={[styles.headerCell, { textAlign: "center" }]}>
                  Taxable Value
                </Text>
              </View>

              {value.taxType === "cgstAndSgst" ? (
                <>
                  <View style={[styles.tableCol, { width: "15%" }]}>
                    <View style={[{ flexDirection: "column", width: "100%" }]}>
                      <View
                        style={{
                          width: "100%",
                          borderBottom: "1.2px solid black",
                        }}
                      >
                        <Text
                          style={[styles.headerCell, { textAlign: "center" }]}
                        >
                          CGST
                        </Text>
                      </View>
                      <View style={[{ width: "100%", flexDirection: "row" }]}>
                        <View
                          style={{
                            borderRight: "1.2px solid black",
                            width: "40%",
                          }}
                        >
                          <Text style={[styles.headerCell]}>Rate</Text>
                        </View>
                        <View style={{ width: "60%" }}>
                          <Text style={[styles.headerCell]}>Amount</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.tableCol, { width: "15%" }]}>
                    <View style={[{ flexDirection: "column", width: "100%" }]}>
                      <View
                        style={{
                          width: "100%",
                          borderBottom: "1.2px solid black",
                        }}
                      >
                        <Text
                          style={[styles.headerCell, { textAlign: "center" }]}
                        >
                          SGST
                        </Text>
                      </View>
                      <View style={[{ width: "100%", flexDirection: "row" }]}>
                        <View
                          style={{
                            borderRight: "1.2px solid black",
                            width: "40%",
                          }}
                        >
                          <Text style={[styles.headerCell]}>Rate</Text>
                        </View>
                        <View style={{ width: "60%" }}>
                          <Text style={[styles.headerCell]}>Amount</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </>
              ) : (
                <View style={[styles.tableCol, { width: "15%" }]}>
                  <View style={[{ flexDirection: "column", width: "100%" }]}>
                    <View
                      style={{
                        width: "100%",
                        borderBottom: "1.2px solid black",
                      }}
                    >
                      <Text
                        style={[styles.headerCell, { textAlign: "center" }]}
                      >
                        IGST
                      </Text>
                    </View>
                    <View style={[{ width: "100%", flexDirection: "row" }]}>
                      <View
                        style={{
                          borderRight: "1.2px solid black",
                          width: "40%",
                        }}
                      >
                        <Text style={[styles.headerCell]}>Rate</Text>
                      </View>
                      <View style={{ width: "60%" }}>
                        <Text style={[styles.headerCell]}>Amount</Text>
                      </View>
                    </View>
                  </View>
                </View>
              )}
              <View
                style={[
                  styles.tableCol,
                  styles.tableLastColumnBorderZero,
                  {
                    width: `${
                      value?.taxType === "cgstAndSgst" ? "29%" : "44%"
                    }`,
                  },
                ]}
              >
                <Text style={[styles.headerCell]}>Total Tax Amount (Rs.)</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "37%" }]}>
                <Text style={[styles.headerCell]}></Text>
              </View>
              <View style={[styles.tableCol, { width: "12%" }]}>
                <Text style={[styles.headerCell, { textAlign: "center" }]}>
                  {caculateWithoutGST(itemArray)}
                </Text>
              </View>
              {value.taxType === "cgstAndSgst" ? (
                <>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "15%", flexDirection: "row" },
                    ]}
                  >
                    <View
                      style={{
                        borderRight: "1.2px solid black",
                        width: "40%",
                        height: "100%",
                      }}
                    >
                      <Text style={[styles.headerCell]}>9 %</Text>
                    </View>
                    <View style={{ width: "60%", height: "100%" }}>
                      <Text style={[styles.headerCell]}>
                        {calulateGST(itemArray, 9)}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "15%", flexDirection: "row" },
                    ]}
                  >
                    <View
                      style={{
                        borderRight: "1.2px solid black",
                        width: "40%",
                        height: "100%",
                      }}
                    >
                      <Text style={[styles.headerCell]}>9 %</Text>
                    </View>
                    <View style={{ width: "60%", height: "100%" }}>
                      <Text style={[styles.headerCell]}>
                        {calulateGST(itemArray, 9)}
                      </Text>
                    </View>
                  </View>
                </>
              ) : (
                <View
                  style={[
                    styles.tableCol,
                    { width: "15%", flexDirection: "row" },
                  ]}
                >
                  <View
                    style={{
                      borderRight: "1.2px solid black",
                      width: "40%",
                      height: "100%",
                    }}
                  >
                    <Text style={[styles.headerCell]}>18 %</Text>
                  </View>
                  <View style={{ width: "60%", height: "100%" }}>
                    <Text style={[styles.headerCell]}>
                      {calulateGST(itemArray, 18)}
                    </Text>
                  </View>
                </View>
              )}
              <View
                style={[
                  styles.tableCol,
                  styles.tableLastColumnBorderZero,
                  {
                    width: `${
                      value?.taxType === "cgstAndSgst" ? "29%" : "44%"
                    }`,
                  },
                ]}
              >
                <Text style={[styles.headerCell]}>
                  {calulateGST(itemArray, 18)}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              width: "100%",
              height: "20px",
              borderBottom: "1.2px solid black",
              padding: "2px 5px",
            }}
          >
            <Text style={{ fontSize: 8 }}>
              Tax Amount (in words):
              {numberToWordsInIndianCurrency(calulateGST(itemArray, 18))}
            </Text>
          </View>
          <View
            style={{
              width: "100%",
              height: "15px",
              borderBottom: "1.2px solid black",
              padding: "2px 5px",
            }}
          >
            <Text style={{ fontSize: 8 }}></Text>
          </View>
          <View
            style={{
              width: "100%",
              height: "28px",
              borderBottom: "1.2px solid black",
              padding: "2px 4px",
              lineHeight: "1.3px",
            }}
          >
            <View>
              <Text style={{ fontSize: 8 }}>Declaration:</Text>
            </View>
            <View>
              <Text style={{ fontSize: 8 }}>
                We declare that this invoice shows the actual price of the
                goods/service described and that all particulars are true and
                correct.
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              height: "auto",
              borderBottom: "1.2px solid black",
            }}
          >
            <View
              style={{
                width: "56%",
                borderRight: "1.2px solid black",
                fontSize: "8px",
                lineHeight: "1.5px",
                padding: "2px 5px 5px 5px",
              }}
            >
              <Text>Company's PAN: AAJCT6877G</Text>
              <Text>Company's Bank Details:</Text>
              <Text>Bank Name: STATE BANK OF INDIA</Text>
              <Text>A/c No.: 31952563006</Text>
              <Text>Branch & IFS Code: RAMRAJATALA & SBIN0001359</Text>
            </View>
            <View
              style={{ width: "5%", borderRight: "1.2px solid black" }}
            ></View>
            <View
              style={{
                width: "39%",
                justifyContent: "space-between",
                fontSize: 8,
                padding: "5px",
              }}
            >
              <Text>for, TRUEWORTHS MINERALS SOLUTIONS PVT. LTD.</Text>
              <View
                style={{
                  paddingBottom: "7px",
                  paddingTop: "7px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Image
                  src={signPerformaInvoice}
                  style={{ width: 150, height: 30, marginBottom: "3px" }}
                />
                <Text style={{ textAlign: "center" }}>
                  (Authorised Signatory)
                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: "100%", height: "15px", padding: "2px 5px" }}>
            <Text style={{ fontSize: 8, textAlign: "center" }}>
              This is a Computer Generated Quotation Invoice,
            </Text>
          </View>
          <View style={{ width: "100%", height: "15px", padding: "2px 5px" }}>
            <Text style={{ fontSize: 8, textAlign: "center" }}>
              PDF Generated Date:- {getTodayDate()}
            </Text>
          </View>
        </View>

        {/* <View>
					<View>
						<Text style={styles.formHeader}>Customer Details</Text>
					</View>

					<View style={styles.form}>
						<View style={styles.formItem}>
							<Text>Name: {value.name}</Text>
						</View>
						<View style={styles.formItem}>
							<Text>Customer Id : {value.customerId}</Text>
						</View>
					</View>
					<View style={styles.form}>
						<View style={styles.formItem}>
							<Text>Email: {value.email}</Text>
						</View>
						<View style={styles.formItem}>
							<Text>Contact Number : {value.phoneNumber}</Text>
						</View>
					</View>

					<View style={styles.form}>
						<View style={styles.formItem}>
							<Text>Address: {value.address}</Text>
						</View>
					</View>

					<View>
						<Text style={styles.formHeader}>Business Details</Text>
					</View>

					<View style={styles.form}>
						<View style={styles.formItem}>
							<Text>PAN: {value.pan}</Text>
						</View>
						<View style={styles.formItem}>
							<Text>GST {value.gstIn}</Text>
						</View>
					</View>

					<View>
						<Text style={styles.formHeader}>Credit Details</Text>
					</View>

					<View style={styles.form}>
						<View style={styles.formItem}>
							<Text>Credit Limit :{value.creditLimitAllowed}</Text>
						</View>
						<View style={styles.formItem}>
							<Text>Credit Period : {value.creditPeriodAllowed}</Text>
						</View>
						<View style={styles.formItem}>
							<Text>Previous Pending Amount : {value.overDue}</Text>
						</View>
					</View>

					<View style={styles.form}>
						<View style={styles.formItem}>
							<Text>Remarks</Text>
							<Text style={{ marginTop: 5 }}>{value.remarks}</Text>
						</View>
					</View>
				</View> */}
      </Page>
    </Document>
  );

  return (
    <>
      <Modal show={pdf} size="xl" onHide={onClose}>
        <Modal.Header className="bg-transparent" closeButton></Modal.Header>
        <Modal.Body>
          <PDFViewer width={"100%"} height={"800px"}>
            <PDF />
          </PDFViewer>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyDocument;

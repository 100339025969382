import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { MdPrint } from 'react-icons/md';
import MyDocument from '../data/pdfDocument'; // Assuming MyDocument is imported

const PdfTemplate = ({ value }) => {
  const [pdf, setPdf] = useState(false);

  const pdfCloseHandler = () => {
    setPdf(false);
  };

  return (
    <>
      <Button
        size="sm"
        onClick={() => setPdf(true)}
        className="border-0 bg-transparent"
      >
        <MdPrint size="1.7em" className="text-primary" />
      </Button>

      {pdf && (
        <MyDocument
          data={value}
          onClose={pdfCloseHandler}
          pdf={pdf}
        />
      )}
    </>
  );
};

export default PdfTemplate;

export function calculateDiscount(
  subTotal = 0,
  discount = 0,
  taxes = 0,
  shippingCost = 0
) {
  const discountedPrice = subTotal - subTotal * (Number(discount) / 100);
  const taxAddedPrice =
    discountedPrice + discountedPrice * (Number(taxes) / 100);
  const shippingAddedPrice = taxAddedPrice + Number(shippingCost);
  return Math.ceil(shippingAddedPrice.toFixed(2));
}

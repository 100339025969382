import useForms from "../../../context/forms/useForms";
import { tableColumnParser } from "../../../util/tableColumnParser";
import useApi from "hooks/useApi";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import TableAction from "Components/TableAction";
import ViewForm from "Components/ViewForm";
import { SwitchButton } from "Components";
import { toast } from "react-toastify";
import useUser from "hooks/useUser";
import MyDocument from "./pdfDocument";
import { format } from "date-fns";
import { MdPrint, MdRemoveRedEye } from "react-icons/md";
import moment from "moment";
import MainForm from "../form";
import { calculateDiscount } from "util/calculate";
import { Button } from "react-bootstrap";
import { useState } from "react";
import PdfTemplate from "../components/pdfTemplate";

const API_NAME = "quotations";

export default function useTable({ page, action }) {
  const { GET, PUT } = useApi();
  const queryClients = useQueryClient();
  const { getForm, loading } = useForms();
  const form = getForm(API_NAME);
  const { accessPath } = useUser(form?.formName);
  const [pdf, setPdf] = useState(false);

  const filterOutData = [
    "quotationsId",
    "customerId",
    "date",
    "validityDate",
    "estimatedDeliveryDate",
    "signatureLine",
    "additionalGSTDetails",
    "additionalContactNo",
    "additionalLocationNameOfContactPerson",
    "additionalEmailId",
    "itemServiceCategory",
    "testAndParameters",
    "totalPrice",
    "customerName",
    "referenceEnquiryId",
  ];

  const response = useQuery({
    queryKey: [
      API_NAME,
      page.page,
      page.size,
      page.column,
      page.keyword,
      page.mongoQuery,
    ],
    queryFn: async () =>
      await GET(
        `/${API_NAME}?page=${page.page}&size=${page.size}&columnName=${page.column}&keyword=${page.keyword}&mongoQuery=${page.mongoQuery}`
      ),
    enabled: accessPath.view,
  });

  const status = useMutation({
    mutationFn: async (body) => await PUT("quotations", body),
  });

  const parseColumn = form ? tableColumnParser(form.formComponents) : []; //gets the columns from the form for table with component key mapping to accessor and label to header ;

  const filter = parseColumn
    .map((item) => ({
      label: item.Header,
      key: item.accessor,
    }))
    .concat({
      label: "User Added Date",
      key: "createdAt",
    });

  const statusHandler = (body) => {
    status.mutate(body, {
      onSuccess: async (data) => {
        await queryClients.invalidateQueries(["user"]);
        toast.success("User status changed");
      },
      onError: () => {
        toast.error("Failed to change status");
      },
    });
  };
  const pdfCloseHandler = () => {
    setPdf(false);
  };
  const additionalColumn = [
    {
      Header: (
        <span>
          Item <br />
          Service <br />
          Category
        </span>
      ),
      accessor: "itemServiceCategory",
      Cell: ({ row }) => {
        const value = row.original;
        return <>{value.itemServiceCategory.name}</>;
      },
    },
    {
      Header: "Total Price",
      accessor: "totalPrice",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <>
            {calculateDiscount(
              value?.totalPrice || 0,
              value?.discount || 0,
              value?.rateOfTaxes || 0,
              value?.shippingHandlingCosts || 0
            ).toLocaleString("en-IN", {})}
          </>
        );
      },
    },
    {
      Header: "ETA",
      accessor: "estimateddeliverydate",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "9ch", display: "inline-block" }}>
            {value?.estimatedDeliveryDateFormat
              ? moment(new Date(value?.estimatedDeliveryDateFormat)).format(
                  "DD/MM/YYYY"
                )
              : "-"}
          </span>
        );
      },
    },
    {
      Header: "Validity Date",
      accessor: "validityDate",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "9ch", display: "inline-block" }}>
            {value?.convertedValidityDate
              ? moment(value?.convertedValidityDate).format("DD/MM/YYYY")
              : "-"}
          </span>
        );
      },
    },
    {
      Header: "Created On",
      accessor: "createdOn",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "9ch", display: "inline-block" }}>
            {value.createdAt && format(value.createdAt, "dd/MM/yyyy")}
          </span>
        );
      },
    },
    {
      Header: (
        <span>
          Approval <br />
          Status
        </span>
      ),
      accessor: "status",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "8ch", display: "inline-block" }}>
            {value.status === "Reject"
              ? "Request Resubmission"
              : value.status
              ? value.status
              : "Pending"}
          </span>
        );
      },
    },
    {
      Header: (
        <span>
          Quotation <br />
          Status
        </span>
      ),
      accessor: "usedstatus",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <SwitchButton
            loading={status.isPending || response.isFetching}
            active={!value.usedStatus}
            disabled={true}
            // onChange={(status) => {
            // 	statusHandler({
            // 		_id: value._id,
            // 		status: status ? "active" : "inactive",
            // 	});
            // }}
          />
        );
      },
    },
    {
      Header: "View",
      accessor: "view",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <div>
            <MdRemoveRedEye
              size={"1.3em"}
              className="text-primary"
              role="button"
              onClick={() => action.onView(value)}
            />
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        const value = row.original;

        return (
          <TableAction
            path={API_NAME}
            value={value}
            editAction={action.onEdit}
            accesspoint="customer"
            showPrintButton={false}
            showRevised={
              value?.status
                ? value.status === "Accept" && !value.usedStatus
                  ? true
                  : false
                : false
            }
            showEdit={!value.usedStatus}
            showDelete={false}
            revisedAction={action.onRevised}
            PdfTemplate={() => <PdfTemplate value={value} />}
          />
        );
      },
    },
  ];

  const columns = [
    ...parseColumn.filter((item) => !filterOutData.includes(item.accessor)),
    {
      Header: "Quotation ID",
      accessor: "quotationsId",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "12ch", display: "inline-block" }}>
            {value?.quotationsId}
          </span>
        );
      },
    },
    {
      Header: "Quotation Date",
      accessor: "quotationDateFormat",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "9ch", display: "inline-block" }}>
            {value?.quotationDateFormat
              ? moment(new Date(value?.quotationDateFormat)).format(
                  "DD/MM/YYYY"
                )
              : "-"}
          </span>
        );
      },
    },
    {
      Header: (
        <span>
          Parent <br />
          Quotation
        </span>
      ),
      accessor: "parentQuotation",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "12ch", display: "inline-block" }}>
            {value?.parentQuotation?.quotationsId
              ? value?.parentQuotation?.quotationsId
              : "-"}
          </span>
        );
      },
    },
    {
      Header: "Ref Enq. ID",
      accessor: "referenceEnquiryId",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "12ch", display: "inline-block" }}>
            {value?.referenceEnquiryId}
          </span>
        );
      },
    },

    {
      Header: <span>Customer ID</span>,
      accessor: "customerId",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span style={{ width: "12ch", display: "inline-block" }}>
            {value?.customerId}
          </span>
        );
      },
    },
    {
      Header: "Customer Name",
      accessor: "customerName",
      Cell: ({ row }) => {
        const value = row.original;
        return (
          <span
            style={{
              minWidth: "auto",
              width: "max-content",
              display: "inline-block",
              whiteSpace: "nowrap",
            }}
          >
            {value?.customerName}
          </span>
        );
      },
    },
    {
      Header: (
        <span>
          Customer <br />
          Location
        </span>
      ),
      accessor: "customerLocation",
      Cell: ({ row }) => {
        const value = row.original;
        const parts = value?.additionalCity
          ? value?.additionalCity?.split(" - ")
          : "-";
        let city;
        city = parts[1]?.trim() || parts[0]?.trim() || "";

        return (
          <span style={{ width: "12ch", display: "inline-block" }}>
            {city || value?.customerDetails?.city || "-"}
          </span>
        );
      },
    },
  ].concat(additionalColumn);
  const rows = response.data ? response.data.response.data : [];

  const pagination = response.data
    ? response.data.response
    : { page: 0, showing: 0, total: 0 };
  return {
    columns,
    rows,
    loading: loading || response.isLoading,
    filter,
    pagination,
  };
}

import { useQuery } from "@tanstack/react-query";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { calculateDiscount } from "util/calculate";
import CommonUtil from "util/CommonUtil";

const MainForm = ({
  updatemode,
  data,
  onSubmit,
  viewMode = false,
  actionMode = "",
}) => {
  const [formData, setFormData] = useState({
    referenceEnquiryId: "",
    preparedByNameTitle: "",
    testsAndParameters: [],
    quotationDate: moment(new Date()).format("YYYY-MM-DD"),
    quotationDetails: "",
    discount: "",
    taxes: "GST",
    rateOfTaxes: "",
    shippingHandlingCosts: "",
    otherTermsAndConditions: "",
    paymentMethod: "",
    paymentSchedule: "",
    estimatedDeliveryDate: "",
    deliveryTerms: "",
    additionalNotesRemark: "",
    specialTermsAndConditions: "",
    validityDate: "",
  });

  const [updateData, setUpdateData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [referenceEnquiryIds, setReferenceEnquiryIds] = useState([]);
  const [employeeLists, setEmployeeLists] = useState([]);
  const [enquiryDetails, setEnquiryDetails] = useState({});
  const [parameterWithCost, setParameterWithCost] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [locationDetails, setLocationDetails] = useState({
    additionalCity: "",
    additionalGSTDetails: "",
    additionalNameOfContactPerson: "",
    additionalContactNo: "",
    additionalEmailId: "",
    additionalAddress: "",
    additionalLocationId: "",
  });
  const [error, setError] = useState({});
  const mandatoryField = ["referenceEnquiryId"];
  const { GET } = useApi();
  const enquiryList = useQuery({
    queryKey: ["referenceEnquiryIds"],
    queryFn: async () => await GET("/enquiry?status=active"),
    refetchOnWindowFocus: true,
  });

  const employeeList = useQuery({
    queryKey: ["employeeLists"],
    queryFn: async () => await GET("/employeemaster?limit=100&skip=0"),
    refetchOnWindowFocus: true,
  });

  const locationData = useQuery({
    queryKey: ["locationData", referenceEnquiryIds, enquiryDetails.customerId],
    queryFn: async () =>
      await GET(`quotations/locations/${enquiryDetails.customerId}`),
    refetchOnWindowFocus: true,
    enabled: !!enquiryDetails.customerId,
  });

  useEffect(() => {
    if (!enquiryList.isLoading) {
      setReferenceEnquiryIds(enquiryList.data.response.data);
    }
  }, [enquiryList.isLoading, enquiryList.data]);

  useEffect(() => {
    if (!employeeList.isLoading) {
      setEmployeeLists(employeeList.data.response.data);
    }
  }, [employeeList.isLoading, employeeList.data]);

  useEffect(() => {
    if (!locationData.isLoading) {
      const locationListResponse = locationData.data?.response?.data || [];
      setLocationList(locationListResponse);
      const selectedLocation = locationListResponse.find(
        (location) => location.additionalLocationId === "0"
      );
      setLocationDetails(selectedLocation);
      setFormData((prev) => ({
        ...prev,
        additionalLocationId: selectedLocation?.additionalLocationId || "",
      }));
    }
  }, [locationData.isLoading, locationData.data]);

  useEffect(() => {
    if (!locationData.isLoading && data && (viewMode || updatemode)) {
      const selectedLocation = locationList.find(
        (location) =>
          location.additionalLocationId === data.additionalLocationId
      );
      setLocationDetails(selectedLocation);
    }
  }, [locationData.isLoading, locationList, viewMode, updatemode, data]);

  useEffect(() => {
    console.log("LOC DETAILS ============", locationDetails);
  }, [locationDetails, data]);

  useEffect(() => {
    setParameterWithCost(enquiryDetails?.parameterWithCost || {});
    if (enquiryDetails?.parameterWithCost) {
      let total = 0;
      let totalPrice = 0;
      const arrData = enquiryDetails.testsAndParameters.map((item, idx) => {
        total = calculateParameter(
          enquiryDetails?.parameterWithCost[
            CommonUtil.breakMethodAndTestAndParameter(item).testAndParameter
          ] || 0,
          Number(enquiryDetails?.quantity) || 0,
          enquiryDetails?.discount[idx]
        );
        totalPrice += calculateParameter(
          enquiryDetails?.parameterWithCost[
            CommonUtil.breakMethodAndTestAndParameter(item).testAndParameter
          ] || 0,
          Number(enquiryDetails?.quantity) || 0,
          enquiryDetails?.discount[idx],
          enquiryDetails?.gst[idx]
        );
        return {
          name: item,
          quantity: "",
          price: String(
            enquiryDetails?.parameterWithCost[
              CommonUtil.breakMethodAndTestAndParameter(item).testAndParameter
            ]
          ),
          unit: enquiryDetails?.unit || "",
          sampleSize: enquiryDetails?.sampleSize || "",
          quantity: enquiryDetails?.quantity || "",
          gst: enquiryDetails?.gst[idx],
          discount: enquiryDetails?.discount[idx],
          hsnSac: enquiryDetails?.hsnSac[idx],
          totalPrice: total,
        };
      });

      setFormData((prev) => ({
        ...prev,
        testsAndParameters: arrData,
        totalPrice: totalPrice,
        customerId: enquiryDetails.customerId,
      }));
    }
  }, [enquiryDetails]);

  useEffect(() => {
    if (updatemode || viewMode) {
      setUpdateData((prev) => ({
        ...prev,
        _id: data._id,
      }));
      setFormData(data);
      setEnquiryDetails(data.enquiryDetails);
    }
  }, []);

  const handleChange = (name, value, parentField = "", idx = "") => {
    if (parentField) {
      const arr = [...formData[parentField]];
      if (arr[idx]) {
        arr[idx] = {
          ...arr[idx],
          [name]: value,
        };
      } else {
        arr[idx] = {
          name: enquiryDetails.testsAndParameters[idx],
          [name]: value,
        };
      }
      const total = arr.reduce((accumulator, currentValue) => {
        return (
          calculateParameter(
            Number(currentValue.quantity),
            Number(currentValue.price),
            currentValue.discount,
            currentValue.gst
          ) + accumulator
        );
      }, 0);
      if (updatemode) {
        setUpdateData((prev) => ({
          ...prev,
          [parentField]: arr,
          totalPrice: total,
        }));
      }
      setFormData((prev) => ({
        ...prev,
        [parentField]: arr,
        totalPrice: total,
      }));
    } else {
      if (updatemode) {
        setUpdateData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (formData.referenceEnquiryId) {
      console.log(formData);
    }
  }, [formData.referenceEnquiryId]);

  useEffect(() => {
    const isError = errorHandling(formData);
    if (!isError) {
      setDisabled(false);
    }
  }, [formData]);

  const handleSubmit = () => {
    const isError = errorHandling(formData);
    if (!isError) {
      if (actionMode === "Revised") {
        const enquiryDetails = formData.enquiryDetails;
        const arr = [
          "_id",
          "__v",
          "approvedBy",
          "approvedDate",
          "approverDetails",
          "approverName",
          "approverRole",
          "createdAt",
          "customerName",
          "dateFormat",
          "enquiryDetails",
          "estimatedDeliveryDateFormat",
          "itemServiceCategory",
          "quotationsId",
          "referenceEnquiryCreatedAt",
          "remarks",
          "roleDetails",
          "status",
          "testAndParametersName",
          "updatedAt",
          "usedStatus",
        ];

        const obj = { ...formData, parentId: formData._id };

        Object.keys({ ...formData }).forEach((key) => {
          if (arr.includes(key)) {
            delete obj[key];
          }
        });

        obj.referenceEnquiryId = enquiryDetails._id;
        obj.customerId = enquiryDetails.customerId;

        console.log("formData========>", formData, obj);
        onSubmit(obj);
      } else {
        onSubmit(actionMode === "Update" ? updateData : formData);
      }
    }
  };

  const errorHandling = (data) => {
    let error = false;
    Object.entries(data).forEach(([key, value]) => {
      if (mandatoryField.includes(key) && !value) {
        error = true;
      }
    });
    return error;
  };

  const calculateParameter = (quantity, cost, discount, gst) => {
    let basePrice = (Number(quantity) || 0) * (Number(cost) || 0);

    if (discount && discount > 0) {
      const discountAmount = basePrice * (discount / 100);
      basePrice -= discountAmount;
    }

    if (gst && gst > 0) {
      const gstAmount = basePrice * (gst / 100);
      basePrice += gstAmount;
    }

    return Math.ceil(basePrice);
  };

  return (
    <div>
      <div className="formio-component formio-component-form  formio-component-label-hidden">
        <div className="formio-form">
          {(actionMode === "Update" || viewMode) && (
            <div className="form-group has-feedback formio-component formio-component-textarea formio-component-quotationDetails">
              <label className="col-form-label">Quotations Id</label>
              <input
                className="form-control"
                disabled={true}
                placeholder="Quotations Id ( System Generated )"
                value={formData.quotationsId}
                name="quotationsId"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </div>
          )}
          <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
            <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
              <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId  required">
                <label className="col-form-label field-required">
                  Reference Enquiry Id
                </label>
                {!updatemode && !viewMode ? (
                  <select
                    className="form-control choices__input"
                    value={formData.referenceEnquiryId || ""}
                    name="referenceEnquiryId"
                    disabled={updatemode}
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.value);
                      setEnquiryDetails(
                        referenceEnquiryIds.find(
                          (enquiry) => enquiry._id === e.target.value
                        )
                      );
                    }}
                  >
                    <option value="" disabled>
                      -- Select an Enquiry --
                    </option>
                    {enquiryList.isLoading ? (
                      <option>Loading...</option>
                    ) : (
                      referenceEnquiryIds.map((quotation) => (
                        <option key={quotation._id} value={quotation._id}>
                          {quotation.enquiryId}
                        </option>
                      ))
                    )}
                  </select>
                ) : (
                  <input
                    className="form-control"
                    disabled={true}
                    value={formData?.referenceEnquiryId || ""}
                  />
                )}
                {error.referenceEnquiryId && (
                  <span>This field is required</span>
                )}
              </div>
            </div>
            <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
              <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                <label className="col-form-label">Customer Id</label>
                <input
                  className="form-control"
                  disabled={true}
                  value={enquiryDetails?.customerUniqueId || ""}
                />
              </div>
            </div>
          </div>
          <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
            <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
              <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                <label className="col-form-label">Customer Name</label>
                <input
                  className="form-control"
                  disabled={true}
                  value={enquiryDetails?.customerName || ""}
                />
              </div>
            </div>
            <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
              <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                <label className="col-form-label">Quotation Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={formData.quotationDate}
                  name="quotationDate"
                  disabled={viewMode}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group has-feedback formio-component formio-component-textarea formio-component-quotationDetails">
            <label className="col-form-label">Quotation Details</label>
            <textarea
              className="form-control"
              style={{ height: "85px" }}
              value={formData.quotationDetails}
              disabled={viewMode}
              name="quotationDetails"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            ></textarea>
          </div>
          <div className="formio-component formio-component-panel formio-component-descriptionOfProductsServices1">
            <div className="formio-component formio-component-panel formio-component-descriptionOfProductsServices1">
              <div className="mb-2 card border">
                <div className="card-header bg-default">
                  <span className="mb-0 card-title">
                    Description of Products/Services
                  </span>
                </div>
                <div className="card-body">
                  <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
                    <div className="col-md-12 col-md-offset-0 col-md-push-0 col-md-pull-0">
                      <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                        <label className="col-form-label">
                          Item Service Category
                        </label>
                        <input
                          className="form-control"
                          disabled={true}
                          value={
                            enquiryDetails?.itemServiceCategoryRequested || ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
                    <div className="col-md-12 col-md-offset-0 col-md-push-0 col-md-pull-0">
                      <table>
                        {enquiryDetails?.testsAndParameters &&
                          Array.isArray(enquiryDetails.testsAndParameters) && (
                            <thead>
                              <tr>
                                <th
                                  className="col-form-label py-3 pr-3"
                                  style={{
                                    fontWeight: 400,
                                  }}
                                >
                                  Parameter
                                </th>
                                <th
                                  className="col-form-label py-3 pr-3"
                                  style={{
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  HSC/SAC
                                </th>
                                <th
                                  className="col-form-label py-3 pr-3"
                                  style={{
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  Sample <br />
                                  QTY/VOL
                                </th>
                                <th
                                  className="col-form-label py-3 pr-3"
                                  style={{
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  Unit
                                </th>
                                <th
                                  className="col-form-label py-3 pr-3"
                                  style={{
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  No. <br />
                                  Of Sample
                                </th>
                                <th
                                  className="col-form-label py-3 pr-3"
                                  style={{
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  Price
                                </th>
                                <th
                                  className="col-form-label py-3 pr-3"
                                  style={{
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  Discount (%)
                                </th>
                                <th
                                  className="col-form-label py-3 pr-3"
                                  style={{
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  Total Price
                                </th>
                                <th
                                  className="col-form-label py-3 pr-3"
                                  style={{
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  GST (%)
                                </th>
                              </tr>
                            </thead>
                          )}
                        {enquiryDetails?.testsAndParameters &&
                        Array.isArray(enquiryDetails.testsAndParameters) ? (
                          enquiryDetails.testsAndParameters.map(
                            (parameter, idx) => (
                              <tbody>
                                <tr className="py-3 pr-3">
                                  <td
                                    className="py-3 pr-3 col-md-3 col-md-offset-0 col-md-push-0 col-md-pull-0"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <p>
                                      {
                                        CommonUtil.breakMethodAndTestAndParameter(
                                          parameter
                                        ).testAndParameter
                                      }{" "}
                                      ||{" "}
                                      {
                                        CommonUtil.breakMethodAndTestAndParameter(
                                          parameter
                                        ).method
                                      }{" "}
                                    </p>
                                  </td>

                                  <td className="py-3 pr-3 col-md-2 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                    <input
                                      className="form-control"
                                      value={
                                        formData.testsAndParameters?.[idx]
                                          ?.hsnSac || ""
                                      }
                                      disabled
                                      onChange={(e) =>
                                        handleChange(
                                          "hsnSac",
                                          e.target.value,
                                          "testsAndParameters",
                                          idx
                                        )
                                      }
                                    />
                                  </td>

                                  <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                    <input
                                      className="form-control"
                                      name="sampleSize"
                                      value={
                                        formData.testsAndParameters?.[idx]
                                          ?.sampleSize || ""
                                      }
                                      disabled={true}
                                      onChange={(e) =>
                                        handleChange(
                                          "sampleSize",
                                          e.target.value,
                                          "testsAndParameters",
                                          idx
                                        )
                                      }
                                    />
                                  </td>

                                  <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                    <input
                                      className="form-control"
                                      value={
                                        formData.testsAndParameters?.[idx]
                                          ?.unit || ""
                                      }
                                      disabled={true}
                                      onChange={(e) =>
                                        handleChange(
                                          "unit",
                                          e.target.value,
                                          "testsAndParameters",
                                          idx
                                        )
                                      }
                                    />
                                  </td>

                                  <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                    <input
                                      className="form-control"
                                      value={
                                        formData.testsAndParameters?.[idx]
                                          ?.quantity || ""
                                      }
                                      disabled={viewMode}
                                      onChange={(e) =>
                                        handleChange(
                                          "quantity",
                                          e.target.value,
                                          "testsAndParameters",
                                          idx
                                        )
                                      }
                                    />
                                  </td>

                                  <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                    <input
                                      className="form-control"
                                      value={
                                        Number(
                                          formData.testsAndParameters?.[idx]
                                            ?.price
                                        )
                                          ? Number(
                                              formData.testsAndParameters?.[idx]
                                                ?.price
                                            ).toLocaleString("en-IN", {})
                                          : 0
                                      }
                                      disabled={viewMode}
                                      defaultValue={
                                        Number(
                                          parameterWithCost[
                                            CommonUtil.breakMethodAndTestAndParameter(
                                              parameter
                                            ).testAndParameter
                                          ]
                                        )
                                          ? Number(
                                              parameterWithCost[
                                                CommonUtil.breakMethodAndTestAndParameter(
                                                  parameter
                                                ).testAndParameter
                                              ]
                                            ).toLocaleString("en-IN", {})
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          "price",
                                          e.target.value.replace(/,/g, ""),
                                          "testsAndParameters",
                                          idx
                                        )
                                      }
                                    />
                                  </td>

                                  <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                    <input
                                      className="form-control"
                                      value={
                                        formData.testsAndParameters?.[idx]
                                          ?.discount || ""
                                      }
                                      disabled={viewMode}
                                      onChange={(e) =>
                                        handleChange(
                                          "discount",
                                          e.target.value,
                                          "testsAndParameters",
                                          idx
                                        )
                                      }
                                    />
                                  </td>

                                  <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                    <input
                                      className="form-control"
                                      value={
                                        formData.testsAndParameters?.[
                                          idx
                                        ]?.totalPrice?.toLocaleString(
                                          "en-IN",
                                          {}
                                        ) || ""
                                      }
                                      disabled={true}
                                    />
                                  </td>

                                  <td className="py-3 pr-3 col-md-1 col-md-offset-0 col-md-push-0 col-md-pull-0">
                                    <input
                                      className="form-control"
                                      value={
                                        formData.testsAndParameters?.[idx]
                                          ?.gst || ""
                                      }
                                      disabled={viewMode}
                                      onChange={(e) =>
                                        handleChange(
                                          "gst",
                                          e.target.value,
                                          "testsAndParameters",
                                          idx
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            )
                          )
                        ) : (
                          <>{""}</>
                        )}
                      </table>
                    </div>
                  </div>

                  <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
                    <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                      <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                        <label className="col-form-label">Total Price</label>
                        <input
                          className="form-control"
                          disabled={true}
                          value={Number(formData?.totalPrice).toLocaleString(
                            "en-IN",
                            {}
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                      <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                        <label className="col-form-label">Discount</label>
                        <input
                          className="form-control"
                          value={
                            Number(formData.discount)
                              ? Number(formData.discount).toLocaleString(
                                  "en-IN",
                                  {}
                                )
                              : formData.discount
                          }
                          name="discount"
                          disabled={viewMode}
                          onChange={(e) =>
                            handleChange(
                              e.target.name,
                              e.target.value.replace(/,/g, "")
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
                    <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                      <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                        <label className="col-form-label">Taxes</label>
                        <select
                          className="form-control choices__input"
                          value={formData.taxes}
                          disabled={viewMode}
                          name="taxes"
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        >
                          <option value="" disabled>
                            -- Select a tax method --
                          </option>
                          {/* <option value="VAT">VAT</option> */}
                          <option value="GST">GST</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                      <div className="form-group has-feedback formio-component formio-component-textarea formio-component-quotationDetails">
                        <label className="col-form-label">
                          Shipping/Handling Costs
                        </label>
                        <input
                          className="form-control"
                          value={
                            Number(formData.shippingHandlingCosts)
                              ? Number(
                                  formData.shippingHandlingCosts
                                ).toLocaleString("en-IN", {})
                              : ""
                          }
                          name="shippingHandlingCosts"
                          disabled={viewMode}
                          onChange={(e) =>
                            handleChange(
                              e.target.name,
                              e.target.value.replace(/,/g, "")
                            )
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                      <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                        <label className="col-form-label">Rate of Taxes</label>
                        <input
                          className="form-control"
                          value={
                            Number(formData?.rateOfTaxes)
                              ? Number(formData?.rateOfTaxes).toLocaleString(
                                  "en-IN",
                                  {}
                                )
                              : ""
                          }
                          disabled={viewMode}
                          name="rateOfTaxes"
                          onChange={(e) =>
                            handleChange(
                              e.target.name,
                              e.target.value.replace(/,/g, "")
                            )
                          }
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
                    <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                      <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                        <label className="col-form-label">Final Amount</label>
                        <input
                          className="form-control"
                          value={calculateDiscount(
                            formData.totalPrice || 0,
                            formData.discount || 0,
                            formData.rateOfTaxes || 0,
                            formData.shippingHandlingCosts || 0
                          ).toLocaleString("en-IN", {})}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
            <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
              <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                <label className="col-form-label">
                  Follow ups / Status
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={formData.followUpsStatus}
                  name="followUpsStatus"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
              <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                <label className="col-form-label">
                  Other Terms and conditions
                </label>
                <input
                  className="form-control"
                  type="text"
                  disabled={viewMode}
                  value={formData.otherTermsAndConditions}
                  name="otherTermsAndConditions"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="form-group has-feedback formio-component formio-component-textarea formio-component-quotationDetails">
            <label className="col-form-label">
              Option for revised quotation
            </label>
            <input
              className="form-control"
              value={formData.optionForRevisedQuotation}
              name="optionForRevisedQuotation"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div> */}

          <div className="formio-component formio-component-panel formio-component-customerInformation">
            <div className="mb-2 card border">
              <div className="card-header bg-default">
                <span class="mb-0 card-title">Customer Location</span>
              </div>
              <div className="card-body">
                <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
                  <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                    <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                      <label className="col-form-label">Location</label>
                      {viewMode ? (
                        <input
                          className="form-control"
                          value={locationDetails?.additionalCity || ""}
                          disabled
                        />
                      ) : (
                        <select
                          className="form-control choices__input"
                          //   value={formData.referenceEnquiryId || ""}
                          name="locations"
                          // disabled={isDisabled}
                          defaultValue=""
                          value={locationDetails?.additionalLocationId || ""}
                          onChange={(e) => {
                            const selectedLocationId = e.target.value;
                            const selectedLocation = locationList.find(
                              (location) =>
                                location.additionalLocationId ===
                                selectedLocationId
                            );
                            setLocationDetails(selectedLocation);
                            updatemode
                              ? setUpdateData((prev) => ({
                                  ...prev,
                                  additionalLocationId:
                                    selectedLocation?.additionalLocationId ||
                                    "",
                                }))
                              : setFormData((prev) => ({
                                  ...prev,
                                  additionalLocationId:
                                    selectedLocation?.additionalLocationId ||
                                    "",
                                }));
                          }}
                        >
                          <option value="" disabled>
                            -- Select an Location --
                          </option>
                          {locationData.isLoading ? (
                            <option value="" disabled>
                              -- Loading... --
                            </option>
                          ) : (
                            locationList.map((location) => (
                              <option
                                key={location.additionalLocationId}
                                value={location?.additionalLocationId}
                              >
                                {" "}
                                {location?.additionalCity ||
                                  "--No City Selected--"}{" "}
                              </option>
                            ))
                          )}
                        </select>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                    <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                      <label className="col-form-label">GST IN</label>
                      <input
                        className="form-control"
                        disabled={true}
                        value={locationDetails?.additionalGSTDetails || ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
                  <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                    <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                      <label className="col-form-label">
                        Name of Contact Person
                      </label>
                      <input
                        className="form-control"
                        disabled={true}
                        value={
                          locationDetails?.additionalNameOfContactPerson || ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                    <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                      <label className="col-form-label">Contact No</label>
                      <input
                        className="form-control"
                        disabled={true}
                        value={locationDetails?.additionalContactNo || ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
                  <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                    <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                      <label className="col-form-label">Email Id</label>
                      <input
                        className="form-control"
                        disabled={true}
                        value={locationDetails?.additionalEmailId || ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                    <div class="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                      <label className="col-form-label">Address</label>
                      <input
                        className="form-control"
                        disabled={true}
                        value={locationDetails?.additionalAddress || ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="formio-component formio-component-panel formio-component-descriptionOfProductsServices1">
            <div className="mb-2 card border">
              <div className="card-header bg-default">
                <span className="mb-0 card-title">Payment Terms</span>
              </div>
              <div className="card-body">
                <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
                  <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                    <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                      <label className="col-form-label">Payment Method</label>
                      {/* <input
                        className="form-control"
                        value={formData.paymentMethod}
                        name="paymentMethod"
                        disabled={viewMode}
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                      /> */}
                      <select
                        className="form-control choices__input"
                        value={formData.paymentMethod}
                        disabled={viewMode}
                        name="paymentMethod"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                      >
                        <option value="" disabled>
                          -- Select a payment method --
                        </option>
                        <option value="Debit Card">Debit Card</option>
                        <option value="Credit Card">Credit Card</option>
                        <option value="UPI">UPI</option>
                        <option value="Cash">Cash</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                    <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                      <label className="col-form-label">Payment Schedule</label>
                      <input
                        className="form-control"
                        disabled={viewMode}
                        value={formData.paymentSchedule}
                        name="paymentSchedule"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="formio-component formio-component-panel formio-component-descriptionOfProductsServices1">
            <div className="mb-2 card border">
              <div className="card-header bg-default">
                <span className="mb-0 card-title">Delivery Information</span>
              </div>
              <div className="card-body">
                <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
                  <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                    <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                      <label className="col-form-label">
                        Estimated Delivery Date (ETA)
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        value={formData.estimatedDeliveryDate}
                        name="estimatedDeliveryDate"
                        disabled={viewMode}
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                    <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                      <label className="col-form-label">Delivery Terms</label>
                      <select
                        className="form-control choices__input"
                        value={formData.deliveryTerms}
                        disabled={viewMode}
                        name="deliveryTerms"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                      >
                        <option value="" disabled>
                          -- Select a Delivery terms --
                        </option>
                        <option value="FOB">FOB</option>
                        <option value="CIF">CIF</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
            <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
              <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                <label className="col-form-label">
                  Other Terms and conditions
                </label>
                <input
                  className="form-control"
                  type="text"
                  disabled={viewMode}
                  value={formData.otherTermsAndConditions}
                  name="otherTermsAndConditions"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
              <div className="form-group has-feedback formio-component formio-component-textarea formio-component-quotationDetails">
                <label className="col-form-label">
                  Additional Notes / Remark
                </label>
                <input
                  className="form-control"
                  value={formData.additionalNotesRemark}
                  name="additionalNotesRemark"
                  disabled={viewMode}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="form-group has-feedback formio-component formio-component-textarea formio-component-quotationDetails">
            <label className="col-form-label">
              Special Terms and Conditions
            </label>
            <input
              className="form-control"
              value={formData.specialTermsAndConditions}
              name="specialTermsAndConditions"
              disabled={viewMode}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>

          <div className="form-group has-feedback formio-component formio-component-textarea formio-component-quotationDetails">
            <label className="col-form-label">Validity Date</label>
            <select
              className="form-control choices__input"
              value={formData?.validityDate}
              name="validityDate"
              disabled={viewMode}
              onChange={(e) =>
                handleChange(e.target.name, Number(e.target.value))
              }
            >
              <option value="" disabled>
                -- Select Valid Upto --
              </option>
              <option value="15">15 Days</option>
              <option value="30">30 Days</option>
              <option value="60">60 Days</option>
              <option value="90">90 Days</option>
            </select>
          </div>
          {/* <div className="formio-component formio-component-panel formio-component-descriptionOfProductsServices1">
            <div className="mb-2 card border">
              <div className="card-header bg-default">
                <span className="mb-0 card-title">Approval Section</span>
              </div>
              <div className="card-body">
                <div className="row formio-component formio-component-columns formio-component-columns1  formio-component-label-hidden">
                  <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                    <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId  required">
                      <label className="col-form-label field-required">
                        Prepared By (Name/Title)
                      </label>
                      <select
                        className="form-control choices__input"
                        value={formData.preparedByNameTitle || ""}
                        name="preparedByNameTitle"
                        onChange={(e) => {
                          handleChange(e.target.name, e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          -- Select an employee --
                        </option>
                        {employeeList.isLoading ? (
                          <option>Loading...</option>
                        ) : (
                          employeeLists.map((quotation) => (
                            <option key={quotation._id} value={quotation._id}>
                              <strong>Employee Name: </strong>
                              <span> {quotation.employeeName} </span> ||
                              <strong> Employee Id: </strong>
                              <span>{quotation.employeeId}</span>
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-md-offset-0 col-md-push-0 col-md-pull-0">
                    <div className="form-group has-feedback formio-component formio-component-select formio-component-referenceEnquiryId">
                      <label className="col-form-label">
                        Signature Line
                      </label>
                      <ul className="list-group list-group-striped">
                        <li className="list-group-item list-group-header hidden-xs hidden-sm">
                          <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                              <strong>File Name</strong>
                            </div>
                            <div className="col-md-2">
                              <strong>Size</strong>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div
                        className="fileSelector"
                        style={{
                          position: "relative",
                          padding: "15px",
                          border: "2px dashed #ddd",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <i className="fa fa-cloud-upload"></i>
                        Drop files to attach, or
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {!viewMode ? (
            <div className="form-group has-feedback formio-component formio-component-textarea formio-component-quotationDetails">
              <button
                className="btn btn-primary btn-md"
                type="submit"
                disabled={disabled}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          ) : (
            <> </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainForm;

import {
	Dashboard,
	InternalUser,
	RoleAndPermission,
	FormsBuilder,
	FormsList,
	ApprovalList,
	Customer,
	SampleList,
	SampleName,
	ProformaInvoice,
	SampleDispatch,
	SampleReceiving,
	Report,
	JobRegestration,
	UnitOfScale,
	DispatchList,
	SampleRejected,
	SampleAccepted,
	ChemistDataEntry,
	SampleReceiving1,
	SampleAllotment,
	SampleRejected1,
	ChemistDataEntry1,
	ParameterMaster,
	ResultApproval,
	SampleAccepted1,
	Enquiry,
	Department,
	Employee,
	Designation,
	CustomerApproval,
	QuotationApproval
} from "../Pages";

import {
	MdOutlineDashboard,
	MdManageAccounts,
	MdPeopleAlt,
	MdEngineering,
	MdOutlineDynamicForm,
	MdOutlineList,
	MdList,
	MdPerson,
	MdViewList,
	MdDescription,
	MdReport,
	MdDeliveryDining,
} from "react-icons/md";
import { GiChemicalDrop } from "react-icons/gi";

import {
	FaFileInvoice,
	FaMinusCircle,
	FaRegListAlt,
	FaRuler,
} from "react-icons/fa";
import SampleParameter from "Pages/SampleParameter";
import { ImLab } from "react-icons/im";
import { IoIosCalendar, IoMdAddCircleOutline } from "react-icons/io";
import { RiFolderReceivedLine } from "react-icons/ri";
import Quotations from "Pages/Quotations";
import WorkOrder from "Pages/WorkOrder";

const routes = [
	{
		name: "Dashboard",
		key: "dashboard",
		path: "/dashboard",
		icon: <MdOutlineDashboard size={"1.5em"} />,
		subItem: "",
		component: <Dashboard />,
		admin: 0,
	},

	{
		name: "Customers",
		key: "customer",
		path: "/customers",
		icon: <MdPerson size={"1.5em"} />,
		component: <Customer />,
		subItem: "",
		admin: 0,
	},

	{
		name: "Enquiry",
		key: "enquiry",
		path: "/enquiry",
		icon: <MdPerson size={"1.5em"} />,
		component: <Enquiry />,
		subItem: "",
		admin: 0,
	},
	{
		name: "Quotations",
		key: "quotations",
		path: "/quotations",
		icon: <MdPerson size={"1.5em"} />,
		component: <Quotations />,
		subItem: "",
		admin: 0,
	},
	{
		name: "Work Order",
		key: "workorder",
		path: "/workorder",
		icon: <MdPerson size={"1.5em"} />,
		component: <WorkOrder />,
		subItem: "",
		admin: 0,
	},
	{
		name: "Samples",
		key: "samples",
		path: "",
		icon: <GiChemicalDrop size={"1.5em"} />,
		admin: 0,
		subItem: [
			{
				name: "Registration",
				key: "sample registration",
				path: "/sampleinventory",
				icon: <FaRegListAlt size={"1.5em"} />,
				component: <SampleList />,
				subItem: "",
				admin: 0,
			},
			{
				name: "Proforma Invoice",
				key: "proformainvoice",
				path: "/proformainvoice",
				icon: <FaFileInvoice size={"1.5em"} />,
				component: <ProformaInvoice />,
				subItem: "",
				admin: 0,
			},
			{
				name: "Dispatch",
				key: "sample dispatch",
				path: "/sampledispatch",
				icon: <MdDeliveryDining size={"1.5em"} />,
				component: <SampleDispatch />,
				subItem: "",
				admin: 0,
			},
		],
	},
	// {
	// 	name: "Lab Operations",
	// 	key: "lab operations",
	// 	path: "",
	// 	icon: <ImLab size={"1.5em"} />,
	// 	admin: 0,
	// 	subItem: [
	// 		{
	// 			name: "Sample Receiving",
	// 			key: "sample receiving",
	// 			path: "/samplereceiving",
	// 			icon: <RiFolderReceivedLine size={"1.5em"} />,
	// 			component: <SampleReceiving />,
	// 			subItem: "",
	// 			admin: 0,
	// 		},
	// 		{
	// 			name: "Sample Accepted",
	// 			key: "sample accepted list",
	// 			path: "/sampleaccepted",
	// 			icon: <IoMdAddCircleOutline size={"1.5em"} />,
	// 			component: <SampleAccepted />,
	// 			subItem: "",
	// 			admin: 0,
	// 		},
	// 		{
	// 			name: "Sample Rejected",
	// 			key: "sample rejected",
	// 			path: "/samplerejected",
	// 			icon: <FaMinusCircle size={"1.5em"} />,
	// 			component: <SampleRejected />,
	// 			subItem: "",
	// 			admin: 0,
	// 		},
	// 		{
	// 			name: "Chemist Data Entry",
	// 			key: "chemist lab report",
	// 			path: "/chemistdataentry",
	// 			icon: <MdDescription size={"1.5em"} />,
	// 			component: <ChemistDataEntry />,
	// 			subItem: "",
	// 			admin: 0,
	// 		},
	// 	],
	// },
	{
		name: "Lab Operations",
		key: "lab operations",
		path: "",
		icon: <ImLab size={"1.5em"} />,
		admin: 0,
		subItem: [
			{
				name: "Sample Receiving",
				key: "sample receiving",
				path: "/samplereceiving",
				icon: <RiFolderReceivedLine size={"1.5em"} />,
				component: <SampleReceiving1 />,
				subItem: "",
				admin: 0,
			},
			{
				name: "Rejected Sample",
				key: "sample rejected",
				path: "/samplerejected",
				icon: <FaMinusCircle size={"1.5em"} />,
				component: <SampleRejected1 />,
				subItem: "",
				admin: 0,
			},
			// {
			// 	name: "Sample Allotment",
			// 	key: "sample allotment",
			// 	path: "/sampleallotment",
			// 	icon: <IoMdAddCircleOutline size={"1.5em"} />,
			// 	component: <SampleAllotment />,
			// 	subItem: "",
			// 	admin: 0,
			// },
			{
				name: "Result Entry",
				key: "results",
				path: "/results",
				icon: <MdDescription size={"1.5em"} />,
				component: <ChemistDataEntry1 />,
				subItem: "",
				admin: 0,
			},
			{
				name: "Result Approval",
				key: "result approval",
				path: "/resultapproval",
				icon: <MdDescription size={"1.5em"} />,
				component: <ResultApproval />,
				subItem: "",
				admin: 0,
			},
		],
	},
	{
		name: "Approval",
		key: "approval",
		path: "",
		icon: <GiChemicalDrop size={"1.5em"} />,
		admin: 0,
		subItem: [
			{
				name: "Customer Approval",
				key: "customer approval",
				path: "/customerapproval",
				icon: <MdPerson size={"1.5em"} />,
				component: <CustomerApproval />,
				subItem: "",
				admin: 0,
			},
			{
				name: "Quotation Approval",
				key: "quotation approval",
				path: "/quotation-approval",
				icon: <MdList size={"1.5em"} />,
				component: <QuotationApproval/>,
				subItem: "",
				admin: 0,
			},
			{
				name: "Deletion Approval",
				key: "deletion approval",
				path: "/deletion-approvallist",
				icon: <MdList size={"1.5em"} />,
				component: <ApprovalList />,
				subItem: "",
				admin: 0,
			},
		],
	},
	{
		name: "Master",
		key: "master",
		path: "",
		icon: <ImLab size={"1.5em"} />,
		admin: 0,
		subItem: [
			{
				name: "Department",
				key: "department",
				path: "/department",
				icon: <RiFolderReceivedLine size={"1.5em"} />,
				component: <Department />,
				subItem: "",
				admin: 0,
			},
			{
				name: "Designation",
				key: "designation",
				path: "/designation",
				icon: <RiFolderReceivedLine size={"1.5em"} />,
				component: <Designation />,
				subItem: "",
				admin: 0,
			},
			{
				name: "Units For Lab",
				key: "units for lab",
				path: "/parametermaster",
				icon: <RiFolderReceivedLine size={"1.5em"} />,
				component: <ParameterMaster />,
				subItem: "",
				admin: 0,
			},
			{
				name: "Sample Names",
				key: "sample names",
				path: "/sample",
				icon: <MdViewList size={"1.5em"} />,
				component: <SampleName />,
				subItem: "",
				admin: 0,
			},
			{
				name: "Unit Of Scale",
				key: "unitofscale",
				path: "/unitofscale",
				icon: <FaRuler size={"1.5em"} />,
				component: <UnitOfScale />,
				subItem: "",
				admin: 0,
			},
			{
				name: "Parameter",
				key: "sample parameter",
				path: "/sampleparameter",
				icon: <IoIosCalendar size={"1.5em"} />,
				component: <SampleParameter />,
				subItem: "",
				admin: 0,
			},
			{
				name: "Employee",
				key: "employee",
				path: "/employee",
				icon: <MdPerson size={"1.5em"} />,
				component: <Employee />,
				subItem: "",
				admin: 0,
			},
		],
	},
	{
		key: "sample receiving",
		path: "/sampleinventory/:name",
		component: <JobRegestration />,
		subItem: "",
		admin: 0,
	},
	{
		key: "sample dispatch list",
		path: "/sampledispatch/:name",
		component: <DispatchList />,
		subItem: "",
		admin: 0,
	},
	{
		name: "Reports",
		key: "report",
		path: "/report",
		icon: <MdReport size={"1.5em"} />,
		component: <Report />,
		subItem: "",
		admin: 0,
	},
	
	{
		name: "Users & Roles",
		key: "user&roles",
		path: "",
		icon: <MdManageAccounts size={"1.5em"} />,
		admin: 0,
		subItem: [
			{
				name: "Users",
				key: "user",
				path: "/users",
				icon: <MdPeopleAlt size={"1.5em"} />,
				component: <InternalUser />,
				subItem: "",
				admin: 0,
			},
			{
				key: "roles and permissions",
				name: "Roles and Permissions",
				path: "/rolesandpermissions",
				icon: <MdEngineering size={"1.5em"} />,
				component: <RoleAndPermission />,
				subItem: "",
				admin: 0,
			},
		],
	},
	{
		name: "Form Builder",
		path: "/formbuilder",
		admin: 0,
		icon: <MdOutlineDynamicForm size={"1.5em"} />,
		component: <FormsBuilder />,
		subItem: "",
		env: "dev",
	},
	{
		name: "Forms List",
		path: "/formslist",
		admin: 4,
		icon: <MdOutlineList size={"1.5em"} />,
		component: <FormsList />,
		env: "dev",
	},
];

export default routes;

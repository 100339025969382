import { Table, SearchBar, QueryFilter } from "Components";
import useTable from "./data/useTableData";
import { useState } from "react";
import { Row, Col, Modal, Button, Stack } from "react-bootstrap";
import MainForm from "./form";
import useUser from "hooks/useUser";
import EXtraMainFormComponent from "./component/ExtraMainFormComponent";
import { useMutation } from "@tanstack/react-query";
import useApi from "hooks/useApi";
import moment from "moment";
import { toast } from "react-toastify";

export default function WorkOrder() {
  const { accessPath } = useUser("workorder");
  const [formModal, setFormModal] = useState(false);
  const { GET } = useApi();
  const [page, setPage] = useState({
    page: 0,
    size: 10,
    column: "",
    keywoard: "",
    mongoQuery: "",
  });

  const { columns, rows, pagination, filter, loading } = useTable({
    page,
    action: { onEdit, onView },
  });
  const [edit, setEdit] = useState({ data: {}, state: false });
  const [view, setView] = useState({ data: {}, state: false });

  const mutation = useMutation({
    mutationFn: async () => {
      const response = await GET("workorder/export/excel", {
        responseType: "blob",
      });
      return response;
    },
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Workorder Data-at-${moment(new Date()).format(
          "DD/MM/YYY (h:mm a)"
        )}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast.success("Exported successfully");
    },
    onError: (error) => {
      toast.error(error.message || "An error occurred");
    },
  });

  function pageChangeHandler(page, size) {
    setPage((prev) => ({ ...prev, page, size }));
  }

  function searchHandler(column, keyword) {
    setPage((prev) => ({ ...prev, column, keyword }));
  }

  const closeForm = () => {
    if (edit.state) {
      setEdit({ data: {}, state: false });
    }
    if (view.state) {
      setView({ data: {}, state: false });
    }
    setFormModal(false);
  };

  function onView(data) {
    setView({ data: data, state: true });
    setFormModal(true);
  }

  function onEdit(data) {
    setEdit({ data: data, state: true });
    setFormModal(true);
  }

  const exportHandler = () => {
    mutation.mutate();
  };

  function UserForm() {
    if (!accessPath.create) return;

    return (
      <>
        <Modal
          backdrop="static"
          size="xl"
          show={formModal}
          onHide={() => closeForm()}
        >
          <Modal.Header closeButton>
            {edit.state ? "Update" : "Add"} Work Order
          </Modal.Header>
          <Modal.Body>
            <EXtraMainFormComponent
              updatemode={edit.state}
              viewMode={view.state}
              data={view?.data}
              closeForm={closeForm}
            />
          </Modal.Body>
        </Modal>

        <Stack direction="horizontal" gap={2}>
          <Button size="sm" onClick={() => setFormModal(true)}>
            Add Work Order
          </Button>
          <Button
            size="sm"
            onClick={exportHandler}
            disabled={mutation.isLoading}
          >
            {mutation.isLoading ? "Exporting..." : "Export To Excel"}
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <div>
      <Row className="mb-3">
        <Col md={9}>
          <SearchBar filter={filter} onChange={searchHandler} />
        </Col>

        <Col md={3} className="d-flex justify-content-end">
          <UserForm />
        </Col>
      </Row>

      <Table
        table={{ columns, data: rows }}
        pagination={{ ...pagination }}
        onPageChangeHandler={pageChangeHandler}
        loading={loading}
        disabled={!accessPath.view}
        showColumnsForTable={[
          "workOrderId",
          "workOrderNo",
          "dateOfOrder",
          "referenceToQuotationNumber",
          "customerId",
          "customerName",
          // "estimatedDeliveryDate",
          "customerLocation",
          "totalPrice",
          "view",
          "createdAt",
          "contractStartDate",
          "contractEndDate"
        ]}
      />
    </div>
  );
}

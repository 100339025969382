const CommonUtil = {
  breakMethodAndTestAndParameter(value) {
    const regex = /\(\s*([^()]+(?:\([^()]*\))*[^()]*)\s*\)\s*$/;
    const methodMatch = value.match(regex);
    const method = methodMatch ? methodMatch[1].trim() : null;
    const name = value.replace(regex, "").trim();
    return {
      testAndParameter: name,
      method: method,
    };
  },
  getStateNameAndGSTNumber(value) {
    const gstCode = value.slice(0, 2);
    const statesJson = require("./states-gst.json");
    const { states } = statesJson;
    const gstData = states.find((state) => state.gst_code === gstCode);
    return gstData;
  },
};

export default CommonUtil;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApi from "hooks/useApi";
import useUser from "hooks/useUser";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import { BiRevision } from "react-icons/bi";
import { toast } from "react-toastify";

export default function TableAction({
  path,
  value,
  editAction,
  accesspoint,
  PdfTemplate,
  showDelete = true,
  showRevised = false,
  revisedAction = () => {},
  showEdit = true,
  pdfButton = false
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { accessPath } = useUser(accesspoint);
  const { DELETE } = useApi();
  const [toDelete, setToDelete] = useState(false);

  const queryClient = useQueryClient();
  const deletion = useMutation({
    mutationFn: async () => await DELETE(`${path}/${value._id}`),
  });

  const deleteHandler = () => {
    deletion.mutate(
      {},
      {
        onSuccess: async () => {
          toast.success("Item deleted successfully");
          setToDelete(false);
          await queryClient.invalidateQueries({ queryKey: [path] });
          await queryClient.invalidateQueries({ queryKey: ["approvallist"] });
        },
        onError: () => {
          toast.error("Item deletion failed");
        },
      }
    );
  };

  const onEdit = () => {
    editAction(value);
  };

  const onRevised = () => {
    console.log("WORKING REVISED ===========>");
    revisedAction(value);
  };

  return (
    <>
      <Modal show={toDelete} onHide={() => setToDelete(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger border-0"
            disabled={deletion.isPending}
            onClick={deleteHandler}
          >
            {deletion.isPending ? "Deleting..." : "Confirm"}
          </Button>
          <Button
            disabled={deletion.isPending}
            className="bg-primary border-0"
            onClick={() => setToDelete(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {value.deleteStatus === "requested" ? (
        <p style={{ fontSize: "12px" }} className="m-0 text-danger">
          Requested <br />
          for deletion
        </p>
      ) : (
        <div className="d-flex">
          {accesspoint === "Sample Registration"
            ? user.role === "admin" && (
                <Button
                  disabled={!accessPath.edit}
                  size="sm"
                  className="bg-transparent border-0"
                  onClick={onEdit}
                >
                  <MdEdit size="1.7em" className="text-primary" />
                </Button>
              )
            : !showRevised && showEdit && (
                <Button
                  disabled={!accessPath.edit}
                  size="sm"
                  className="bg-transparent border-0"
                  onClick={onEdit}
                >
                  <MdEdit size="1.7em" className="text-primary" />
                </Button>
              )}
          {showDelete && !showRevised && (
            <Button
              disabled={!accessPath.delete}
              size="sm"
              className="  border-0 bg-transparent"
              onClick={() => setToDelete(true)}
            >
              <MdDelete size="1.7em" className="text-primary" />{" "}
            </Button>
          )}
          {showRevised && (
            <Button
              disabled={!accessPath.delete}
              size="sm"
              className="  border-0 bg-transparent"
              onClick={onRevised}
            >
              <BiRevision size="1.7em" className="text-primary" />{" "}
            </Button>
          )}

          {PdfTemplate && <PdfTemplate />}
        </div>
      )}
    </>
  );
}
